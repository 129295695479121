import React from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';

export default function SummaryForm (props){
    const vide = " ";
    const client = props.client;
    const ItemsList = (consommation) => {
      if(consommation !== undefined)
          return (
              consommation.map((item,index)=>{
                  return (
                      <tr key={index}>
                          <th scope='row' >{index}</th>
                          <td>{item.deviceName}</td>
                          <td>{item.count}</td>
                          <td>{item.timeUse}</td>
                          <td>{item.power}</td>
                      </tr>
                  );
              })
          );
      return null;
    }
    const  Total = (consommation) => {
      return (
          <tr className='table-info'>
              <th scope='row' >Total</th>
              <td></td>
              <td >{consommation.length > 0 ? consommation.reduce((total,item)=>{return total + parseInt(item.count)},0) : 0}</td>
              <td></td>
              <td >{consommation.length > 0 ? consommation.reduce(( total,item)=> {return total +(parseInt(item.power) * parseInt(item.count) * parseInt(item.timeUse))} , 0) : 0}</td>
          </tr>
      );
    }
    return (
      <>
        <h4 className="mb-3 text-center">Récapitulatif</h4>
        <Col xs="12">
          
          <Row className="justify-content-center">
            <Col xs ="11">
  
              <Card border="secondary" >
                <Card.Header className="text-center text-primary fw-bolder" >Coordonnées</Card.Header>
                <Card.Body>
                  
  
                  
                    <Row >
                     <label className="col-sm-3 py-2 text-dark fw-bolder">Nom de la société :</label>
                      <Col xs="12" sm="3" className="py-2">
                        <p className="text-danger">{client.information.societyName || vide} </p>
                      </Col>
                      <label className="col-sm-3 py-2 text-dark fw-bolder">Nom :</label>
                      <Col xs="12" sm="3" className="py-2">
                        <p className="text-danger">{client.information.firstName|| vide}</p>
                      </Col>
                      <label className="col-sm-3 py-2 text-dark fw-bolder">Prenom :</label>
                      <Col xs="12" sm="3" className="py-2">
                        <p className="text-danger">{client.information.lastName|| vide}</p>
                      </Col>
                      <label className="col-sm-3 py-2 text-dark fw-bolder">Téléphone :</label>
                      <Col xs="12" sm="3" className="py-2">
                        <p className="text-danger">{client.information.phone|| vide}</p>
                      </Col>
                      <label className="col-sm-3 py-2 text-dark fw-bolder">Email :</label>
                      <Col xs="12" sm="3" className="py-2">
                        <p className="text-danger">{client.information.email|| vide}</p>
                      </Col>
                    </Row>
                 
  
                </Card.Body>
              </Card>
            </Col>
  
            <Col xs ="11" className="py-5">
  
              <Card border="secondary" >
                <Card.Header className="text-center text-primary fw-bolder" >Projet</Card.Header>
                <Card.Body>
                  
  
                  
                    <Row >
                    <label className="col-sm-3  text-dark fw-bolder">Secteur :</label>
                      <Col xs="12" sm="3" >
                        <p className="text-danger">{  client.projects[0].projectSector }</p>
                      </Col>
                      <label className="col-sm-3  text-dark fw-bolder">Type de projet :</label>
                      <Col xs="12" sm="3" >
                        <p className="text-danger">{ client.projects[0].projectType === "SI" ? "Site isolé" : client.projects[0].projectType === "CR"? "Connecté au reseaux" : "Pompage solaire" } </p>
                      </Col>
                      <label className="col-sm-3  py-2 text-dark fw-bolder">référence de steg :</label>
                      <Col xs="12" sm="3" className="py-2">
                        <p className="text-danger"> { client.projects[0].stegRef}</p>
                      </Col>
                      <label className="col-sm-3 py-2 text-dark fw-bolder">Lieu d'installation :</label>
                      <Col xs="12" sm="3" className="py-2">
                        <p className="text-danger">{ client.projects[0].projectLocationType}</p>
                      </Col>
                      <label className="col-sm-3 py-2 text-dark fw-bolder">Addresse :</label>
                      <Col xs="12" sm="3" className="py-2">
                        <p className="text-danger">{ client.projects[0].street}</p>
                      </Col>
                      <label className="col-sm-3 py-2 text-dark fw-bolder">Pays:</label>
                      <Col xs="12" sm="3" className="py-2" >
                        <p className="text-danger">{client.projects[0].country}</p>
                      </Col>
                      <label className="col-sm-3 py-2 text-dark fw-bolder">Ville :</label>
                      <Col xs="12" sm="3" className="py-2">
                        <p className="text-danger">{ client.projects[0].city}</p>
                      </Col>
                      <label className="col-sm-3 py-2 text-dark fw-bolder">Code postale :</label>
                      <Col xs="12" sm="3" className="py-2">
                        <p className="text-danger">{client.projects[0].zip}</p>
                      </Col>
                    </Row>
                
  
                </Card.Body>
              </Card>
            </Col>
  
  
          { client.projects[0].projectType === "SI" || client.projects[0].projectType === "PS"?  
            <Col xs ="11" className="py-2">
  
                <Card border="secondary" >
                  <Card.Header className="text-center text-primary fw-bolder " >Consommation</Card.Header>
                  <Card.Title className="text-center"> {client.projects[0].projectType === "PS" ? "" : "Liste des equipements"}</Card.Title>
                  <Card.Body>
                    
  
                    { client.projects[0].projectType === "PS" ?
                      <Row >
                      <label className="col-4  text-dark fw-bolder">Puissance de pompage :</label>
                        <Col  xs="2" >
                          <p className="text-danger">{  client.projects[0].consommation[0].powerHorse.toString().concat(" Ph")  }</p>
                        </Col>
                        <label className="col-3  text-dark fw-bolder">Puissance :</label>
                        <Col  xs="3" >
                          <p className="text-danger">{  client.projects[0].consommation[0].powerKhw } </p>
                        </Col>
                        <label className="col-4  py-2 text-dark fw-bolder">référence de steg :</label>
                        <Col  xs="3" className="py-2">
                          <p className="text-danger"> { client.projects[0].consommation[0].powerPv }</p>
                        </Col>
  
                      </Row>
                    : 
                    <Row >
                        <Col  xs="12" >
                          <div className='table-responsive'>
                            <table className='table'>
                                <thead>
                                    <tr>
                                        <th scope='col'>#</th>
                                        <th scope='col'>Equipement</th>
                                        <th scope='col'>Nombre d'equipement</th>
                                        <th scope='col'>Heures d'utilisation</th>
                                        <th scope='col'>Puissance</th>
                                    </tr>
                                </thead>
                                <tbody id='added'>
                                    {ItemsList(client.projects[0].consommation)}
                                    {Total (client.projects[0].consommation)}
                                </tbody>
                            </table>
                          </div>
                        </Col>
                        
  
                      </Row>
                      }
  
                  </Card.Body>
                </Card>
              </Col>  : " "}
  
  
              <Col xs ="11" className="py-5">
  
                <Card border="secondary" >
                  <Card.Header className="text-center text-primary fw-bolder">Remarque</Card.Header>
                  <Card.Body>
                  
                  <Card.Text>
                    {client.projects[0].remark}
                  </Card.Text>
  
                  </Card.Body>
                </Card>
                </Col>
          </Row>
        </Col>
      </>
    );
  }