import React from "react";
import Title from "./Title"
import Stat from "./Stat"
import Presentation from "./Presentation"
import TeamMemebers from "./TeamMemebers"
import StegLogo from "../../assets/img/steg.png"
import AnmeLogo from "../../assets/img/anme.png"
import AtfpLogo from "../../assets/img/atfp.png"

import ZouhaierPicutre from '../../assets/img/zouhaier.jpg'
import EmnaPicture from '../../assets/img/emna.jpg'
import ManelPicture from '../../assets/img/manel.jpg'
import WissalPicture from '../../assets/img/wissal.jpg'
import SyrinePicture from '../../assets/img/syrine.jpg'
import FillePicture from '../../assets/img/fille.jpg'
import HommePicture from '../../assets/img/homme.jpg'

var team = [
    {
        id:100,
        name:"BEN MAAMAR Zouhaier",
        position:"Gérant et manager",
        facebook:'https://www.facebook.com/benmaamar',
        linkedIn :'https://www.linkedin.com/in/ben-maamer-zouhaier-05501071/',
        email:'dg@universal-energy.tn',
        photo:ZouhaierPicutre
    },
    {
       id:101,
       name:"LASOUED maroua",
       position:"Directrice des opérations",
       tel:'+21692781700',
       email:'direction@universal-energy.tn',
       photo:FillePicture
    },
    {
       id:102,
       name:"AFDHAL Abir",
       position:"Ingénieur d'étude",
       tel:'+21628994919',
       email:'tech@universal-energy.tn',
       photo:FillePicture
    },
    {
       id:103,
       name:"LABESSI Khalil",
       position:"Responsable Commercial Sud",
       tel:'+21628994918',
       email:'commercial@universal-energy.tn',
       photo:HommePicture 
    },
    {
       id:104,
       name:"HLAILI Emna",
       position:"Responsable Commercial Nord",
       tel:'+21629461007',
       email:'contact.nabeul@universal-energy.tn',
       photo:EmnaPicture 
    },
    {
       id:105,
       name:"FELHI Fakhri",
       position:"Responsable Service aprés vente",
       photo:HommePicture 
    },
    {
       id:106,
       name:"KERKNI Chaima",
       position:"Gestionnaire financier",
       tel:'+21629461067',
       email:'contact@universal-energy.tn',
       photo:FillePicture 
    },
    {
       id:107,
       name:"HAJ BRAHIM Hichem",
       position:"Chef d'équipe",
       tel:'+21628994919',
       photo:HommePicture 
    },
    {
       id:108,
       name:"BOUGUILA Manel",
       position:"Technico-commerciale",
       tel:'+21628994915',
       email:'contact.tataouine@universal-energy.tn',
       photo:ManelPicture
    },
    {
       id:109,
       name:"BELAID Wissal",
       position:"Technico-commerciale",
       tel:'+216228994215',
       photo:WissalPicture
    },
    {
       id:110,
       name:"MJAHED Syrine",
       position:"Commerciale",
       tel:'+21628994920',
       photo:SyrinePicture
    }
]
export default function About(props) {
  return (
    <>
    <Title title="A propos Universal Energy"/>
    <Stat/>
    <Presentation/>

    <div className="container-xxl py-5">
        <div className="container">
          <div className="text-center mx-auto mb-5" style={{maxWidth: '600px'}}>
                <h1 className="mb-4" style={{textShadow: "0px 5px 5px #ffc107",color:"#318af2"}}>Nos Partenaires</h1>
          </div>
          <div className="row">
            <div className="col-sm-6 col-md-4 col-6 ">
                <div className="d-flex justify-content-center ">
                    <img src={StegLogo} style={{width:"200px",height:"160px"}} alt="first"></img>   
                </div>
            </div>
            <div className="col-sm-6  col-md-4 col-6 ">
                <div className="d-flex justify-content-center ">
                    <img src={AnmeLogo} style={{width:"200px",height:"160px"}} alt="second"></img>
                </div>
            </div>
            <div className="col-sm-12  col-md-4 col-12 ">
                <div className="d-flex justify-content-center">
                    <img src={AtfpLogo} style={{width:"200px",height:"160px"}} alt="third"></img>
                </div>
            </div>
          </div>        
        </div>
    </div>
    <div className="container-xxl py-5"  id="team_component">
        <div className="container">

            <div className="text-center mx-auto mb-5" style={{maxWidth: '600px'}}>
                <h1 className="mb-4" style={{textShadow: "0px 5px 5px #ffc107",color:"#318af2"}}>Les membres de l'équipe</h1>
            </div>

            <TeamMemebers
                team={team}
                listNumber={3}
            />  
            
        </div>
    </div>
   
    </> 
    );
}