import React from "react";
import {Link,useLocation} from "react-router-dom";

export default function Title(props) {
    let location = useLocation();
    let titles = {about:"A propos Universal Energy", service:"Nos services", recruitment:"Les Offres d'emploies",contact:"Contact", solarPanels : "Panneaux solaire"}

  return (
    <div className="container-fluid page-header py-5 mb-5">
        <div className="container py-5">
            <div className="row justify-content-align">
                {location.pathname.split('/').splice(1,3).map((item,index)=>{
                    if(location.pathname.split('/').splice(1,3).length === 1 ){
                        return (
                            <div key={index} className="col-auto">
                                <h1 className="display-5 text-white mb-3 ">{titles[item]}</h1>
                            </div>
                        )
                    }else if(location.pathname.split('/').splice(1,3).length >= 1){
                        return (
                            <div key={index} className="col-auto">
                                <div className="row justify-content-align">
                                    <div  className="col-auto">
                                        <Link to={index === 0 ? "/" + item : item}><h1 className="display-5 text-white mb-3 ">{titles[item]}</h1></Link>
                                    </div>
                                   { location.pathname.split('/').splice(1,3).length -1  >= index && (location.pathname.split('/').splice(1,3)[index+1] === "" || location.pathname.split('/').splice(1,3)[index+1] === undefined)? '' :<div className="col-auto">
                                        <h1 className="display-5 text-white mb-3 "> > </h1>
                                    </div> }
                                </div>
                            </div>
                        );
                    }
                    return ""
                })}
            </div>
        </div>
    </div>
    );
}