import React from "react";
import getTranslation from "./HeaderLang";


export default function Upheader(props) {
  return (
    <div className="container-fluid bg-dark p-0">
          <div className="row gx-0 d-none d-xl-flex">
              <div className="col-lg-8 px-4 text-start">
                  <div className="h-100 d-inline-flex align-items-center me-4">
                      <small className="fa fa-map-marker-alt text-primary me-2"></small>
                      <small>{getTranslation(props.lang,'location')}</small>
                  </div>
                  <div className="h-100 d-inline-flex align-items-center">
                      <small className="far fa-clock text-primary me-2"></small>
                      <small>{getTranslation(props.lang,'availability').split('|')[0]}  </small>
                      <small> &nbsp;&nbsp; {getTranslation(props.lang,'availability').split('|')[1]} </small>
                  </div>
              </div>
              <div className="col-lg-4 px-5 text-end">
                  <div className="h-100 d-inline-flex align-items-center me-4">
                      <small className="fa fa-phone-alt text-primary me-2"></small>
                      <small>+216 28 994 313</small>
                  </div>
                  <div className="h-100 d-inline-flex align-items-center me-4">
                      <small className="fa fa-phone-alt text-primary me-2"></small>
                      <small>+216 28 994 918</small>
                  </div>
                  <div className="h-100 d-inline-flex align-items-center mx-n2">
                      <a className="btn btn-square " href="https://www.facebook.com/universal.energy.tn">
                        <i className="fab fa-facebook-f text-primary rounded-0 "></i></a> 
                  </div>
              </div>
          </div>
      </div>
    );
}
