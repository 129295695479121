import React , {useState,useEffect} from "react";

const stats = {satisfiedClients:324,doneProjects:600,experts:15}
export default function Stat(props) {
    const [countDoneProjects,setCountDoneProjects] = useState(0);
    const [countSatisfiedClients,setCountSatisfiedClients] = useState(0);
    const [countExperts,setCountExperts ] = useState(0);
    useEffect(()=>{
        const timer = setTimeout(() => {
            if(countDoneProjects <  stats.doneProjects){
                setCountDoneProjects((countDoneProjects) => countDoneProjects + 1);
            }
           /* if(countSatisfiedClients <  stats.satisfiedClients){
                setCountSatisfiedClients((countSatisfiedClients) => countSatisfiedClients + 1);
            }
            if(countExperts < stats.experts){
                setCountExperts((countExperts) => countExperts + 1);
            }*/
          }, 10);
        return () => clearTimeout(timer)
    },[countDoneProjects,countExperts])
  return (
    <div className="container-xxl py-5" id="feature1_component">
    <div className="container">
        <div className="row justify-content-center">

            <div className="col-md-4 col-4 text-center  ">
                <div className="d-flex justify-content-center mb-4">
                    <div className="btn-lg-square bg-info rounded-circle me-3">
                        <i className="fa fa-check text-white"></i>
                    </div>
                    <h1 className="mb-0" data-toggle="counter-up">{countDoneProjects}</h1>
                </div>
                <h5 className="mb-3">Projets effectués</h5>
                <span></span>
            </div>

           {/* <div className="col-md-4 col-4 text-center">
                <div className="d-flex justify-content-center mb-4">
                    <div className="btn-lg-square bg-info rounded-circle me-3">
                        <i className="fa fa-users text-white"></i>
                    </div>
                    <h1 className="mb-0" data-toggle="counter-up">{countSatisfiedClients}</h1>
                </div>
                <h5 className="mb-3">Clients Satisfait</h5>
                <span></span>
            </div>
            
            <div className="col-md-4 col-4 text-center">
                <div className="d-flex justify-content-center mb-4">
                    <div className="btn-lg-square bg-info rounded-circle me-3">
                        <i className="fa fa-users-cog text-white"></i>
                    </div>
                    <h1 className="mb-0" data-toggle="counter-up">{countExperts}</h1>
                </div>
                <h5 className="mb-3">Experts et Ingénieur</h5>
                <span></span>
            </div>*/}
        </div>
    </div>
</div>
    );
}