import React,{useState} from "react";
import Carousel from 'react-bootstrap/Carousel'



export default function TeamMemebers(props)  {
     const{nextIcon,prevIcon} = {prevIcon : <button className="carousel-control-prev" style={{width:'50px',top:props["listNumber"] === 1 ? '355px' : "0px"}} type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev"><span className="carousel-control-prev-icon bg-success rounded-circle" aria-hidden="true"></span><span className="visually-hidden">Previous</span></button> ,
     nextIcon :<button className="carousel-control-next" type="button" style={{width:'50px',top:props["listNumber"] === 1 ? '355px' : "0px"}} data-bs-target="#carouselExampleControls" data-bs-slide="next"><span className="carousel-control-next-icon bg-success rounded-circle" aria-hidden="true"></span><span className="visually-hidden">Next</span></button>
    } 
  return (
        <Carousel id="carouselExampleControls" nextIcon ={nextIcon} prevIcon={prevIcon}data-bs-ride="carousel" slide>
            {
                createCarouselInner( props.team, props.listNumber)
            }
        </Carousel>
    );
  }


function listTeam (team,listNumber){
  return  team.map((item)=>{
                      
       return <div className={listNumber === 3 ? "col-lg-4 col-md-4" : "col-lg-12 col-md-12"} key={item.id} style={{zIndex:2}} >
       <div className="team-item rounded overflow-hidden">
           <div className="d-flex">
               <img className="img-fluid w-75" src={item.photo} alt=""></img>
               <div className="team-social w-25">
                   {facebook(item.facebook)}
                   {linkedin(item.linkedIn)}
                   <Tel link={item.tel} />
                   <Email link={item.email}/>
               </div>
           </div>
           <div className="p-4">
               <h5>{item.name}</h5>
               <span>{item.position}</span>
           </div>
       </div>
   </div>
       
   })
}
function createCarouselInner(team,listNumber){
    const innerCount = [...Array(Math.ceil(team.length/listNumber)).keys()]
    return innerCount.map((i)=>{
        return createCarouselItem(listTeam(team,listNumber).splice(i*listNumber,listNumber),i)
    })

}
function createCarouselItem (elem,key){
   return (<Carousel.Item key={key} >
   <div className="container-xxl py-5" >
     <div className="container" style={{height:"375px"}}>
      <div className="row g-4">
          
          {elem[0]}
          {elem[1] ? elem[1] : '' }
          {elem[2] ? elem[2] : '' }
      </div>
      </div>
      </div> 
      </Carousel.Item>); 
}

function facebook (link){
   if( link !== undefined)
    return (
       <a target="_blank" rel="noopener noreferrer" className="btn btn-lg-square btn-outline-primary rounded-circle mt-3" href={link}><i className="fab fa-facebook-f"></i></a>
    );
}
function linkedin(link){
   if( link !== undefined)
    return (
       <a target="_blank" rel="noopener noreferrer" className="btn btn-lg-square btn-outline-primary rounded-circle mt-3" href={link}><i className="fab fa-linkedin"></i></a>
    );
}
function Email (props){
    const [value,setValue] = useState(<i className="fa fa-envelope"></i>)
    const copy = (e)=>{
        navigator.clipboard.writeText(props.link);
        setValue("Copié")
        setTimeout(function(){setValue(<i className="fa fa-envelope"></i>)  }, 1000)
    }
    if( props.link !== undefined)
    return (
       <button  onClick={(e)=>{copy(e)}}  className="btn btn-lg-square btn-outline-primary rounded-circle mt-3" title={props.link} data-bs-custom-class="custom-tooltip" data-bs-toggle="tooltip" >{value}</button>
    );
    else   
        return ('');
}
function Tel(props){
    const [value,setValue] = useState(<i className="fa fa-phone-alt"></i>)
    const copy = (e)=>{
        navigator.clipboard.writeText(props.link);
        setValue("Copié")
        setTimeout(function(){setValue(<i className="fa fa-phone-alt"></i>)  }, 1000)
    }
    if (props.link !== undefined)
    return (
       <button onClick={(e)=>{copy(e)}} className="btn btn-lg-square btn-outline-primary rounded-circle mt-3"  title={props.link} data-bs-custom-class="custom-tooltip" data-bs-toggle="tooltip" >{value}</button>
    );
    else
    return ('');
}


