import React from "react";
import Title from "../about/Title"
import CandidatureSpontanee from "./CandidatureSpontanee";

export default function Recruitment(props) {
  return (
    <>
        <Title title="Les Offres d'emploies"/>
        <CandidatureSpontanee/>
    </>
    );
}