import React, {useState} from "react";
import Lome from "../../assets/img/img-600x400-lome.jpg"
import SbikhaPompage from "../../assets/img/img-600x400-SbikhaPompage.jpg"
import AichaHotel from "../../assets/img/img-600x400-AichaHotel.jpg"
import MairieMidoun from "../../assets/img/IMG-600x400-MairieMidoun.jpg"
import ResidenceYassin from "../../assets/img/img-600x400-ResidenceYassin.jpg"

const projectType = [
    "Tout",
    "Site isolé",
    "Pompage d'eau",
    "Connecté aux réseaux"
]

const projects = [
    {
        id :0,
        name : "Projet à Lomé",
        description : "Site isolé d'une puissance 5.25 kwc à Lomé, Togo",
        location : 'Lomé',
        country : 'Togo',
        pictures : [Lome],
        type:'Site isolé'
    },
    {
        id :1,
        name : "Projet à Sbikha",
        description : "Installation photovoltaique pour pompage de 20 chevaux A Sbikha, Kairouane",
        location : 'Sbikha',
        country : 'Tunisie',
        pictures : [SbikhaPompage],
        type:"Pompage d'eau"
    },
    {
        id :2,
        name : "Projet à Djerba",
        description : "Installation  photovoltaique connecté au réseau 12,4 kwc à Djerba, Tunisie",
        location : 'Djerba',
        country : 'Tunisie',
        pictures : [AichaHotel],
        type:"Connecté aux réseaux"
    },
    {
        id :3,
        name : "Projet à Djerba",
        description : "Installation photovoltaique connecté au réseau à mairie midoun 35 KWC en Tunisie",
        location : 'Djerba',
        country : 'Tunisie',
        pictures : [MairieMidoun],
        type:"Connecté aux réseaux"
    },
    {
        id :4,
        name : "Projet à Djerba",
        description : "Installation photovoltaique connecté au réseau de 15 kwc pour une résidence privée à Djerba, Tunisie.",
        location : 'Djerba',
        country : 'Tunisie',
        pictures : [ResidenceYassin],
        type:"Connecté aux réseaux"
    }
]

export default function Projects(props) {
    const [activeType,SetActiveType] = useState(0);

    const activeHandlerType =(e)=>{
            SetActiveType(projectType.findIndex((item)=> {return item === e.target.innerText}));
    }
  return (
    <div className="container-xxl py-5" id="project_component">
        <div className="container">
            <div className="text-center mx-auto mb-5 " style={{maxWidth: '600px'}}>
                <h6 className="text-primary">Nos Projets</h6>
                <h1 className="mb-4">Visitez nos derniers projets solaire et d'energie renouvelable </h1>
            </div>
            <div className="row mt-n2 ">
                <div className="col-12 text-center">
                    <ul className="list-inline mb-5" id="portfolio-flters">
                        <ProjectType projectType={projectType} active={activeType} onClick={(e)=> activeHandlerType(e)}/>
                    </ul>
                </div>
            </div>
            <div className="row g-4 portfolio-container ">
                {  
                   <CreateProjectList activeType={activeType}/>
                }
            </div>
        </div>
    </div>
    );
}

function ProjectType(props){
    return props.projectType.map((item, index)=>{
            return <li className={index === props.active ? "mx-2 active" : "mx-2"} onClick={props.onClick}  key={index}>{item}</li>
    })
}

function CreateProjectList(props){
    return projects.filter((item)=>{
        return (projectType[props.activeType] === item.type || projectType[props.activeType] ==='Tout')
    }).map((item)=>{return CreateProjectItem(item)}) 
}

function CreateProjectItem(item){
    return (
        <div className="col-lg-4 col-md-6 portfolio-item first" key={item.id.toString()}>
            <div className="portfolio-img rounded overflow-hidden">
                <img className="img-fluid" src={item.pictures[0]} alt=""></img>
                <div className="portfolio-btn">
                    <a className="btn btn-lg-square btn-outline-light rounded-circle mx-1" href={item.pictures[0]} data-lightbox="portfolio"><i className="fa fa-eye"></i></a>
                </div>
            </div>
            <div className="pt-3">
                <p className="text-primary mb-0">{item.name}</p>
                <hr className="text-primary w-25 my-2"></hr>
                <h5 className="lh-base"> {item.description}</h5>
            </div>
        </div>
    );
}