import React from "react";
import Title from "../about/Title";
import ContactStruct from "./ContactStruct";

export default function Contact(props) {
  return (
    <>
      <Title title="Contact"/>
      <ContactStruct/>
    </>
    );
}