import React, {useRef,useState,useEffect} from "react";
import {Container,Dropdown,DropdownButton}  from 'react-bootstrap';
import styled from 'styled-components'
import useAuth from "../../../../services/authentication/useAuth";
import {
    useNavigate, 
    Outlet,
    useLocation
  } from "react-router-dom";

export default function Admin (){
    const ref = useRef();
    const [reduceSideBar,setReduceSideBar] = useState(true);
    const [setting,setSetting] = useState(3);
    const Style = styled.div`
    .b-example-divider {
        height: 3rem;
        background-color: rgba(0, 0, 0, .1);
        border: solid rgba(0, 0, 0, .15);
        border-width: 1px 0;
        box-shadow: inset 0 .5em 1.5em rgba(0, 0, 0, .1), inset 0 .125em .5em rgba(0, 0, 0, .15);
      }
    
      .b-example-vr {
        flex-shrink: 0;
        width: 1.5rem;
        height: 100vh;
      }
    
      .bi {
        vertical-align: -.125em;
        fill: currentColor;
      }
    
      .nav-scroller {
        position: relative;
        z-index: 2;
        height: 2.75rem;
        overflow-y: hidden;
      }
    
      .nav-scroller .nav {
        display: felx;
        flex-wrap: nowrap;
        padding-bottom: 1rem;
        margin-top: -1px;
        overflow-x: auto;
        text-align: center;
        white-space: nowrap;
        -webkit-overflow-scrolling: touch;
      }
      .nav { 
        display : inline-block;
      }
`   
    const auth=useAuth()

    useEffect(()=>{
        if(reduceSideBar === true){
            ref.current.style = "width:4.5rem;"
        }else if (reduceSideBar === false ){
            ref.current.style = "width:170px;"
        }
    },[reduceSideBar])

    const onChangeSetting = (id) =>{
        setSetting(id)
    }
    const handleSideBar = (e)=>{
        setReduceSideBar(!reduceSideBar)
    }
    return (<>
    <Style>
            <Container className="d-flex  justify-content-center" fluid>
                <div className=" d-block p-2 bg-light" ref={ref}>
                    <div className={reduceSideBar === false ? 
                        "text-center link-dark text-decoration-none" 
                        : "text-center p-3 link-dark text-decoration-none"} 
                        onClick={handleSideBar}
                    >
                        <svg  width="20"  height="20" className="bi bi-gear" viewBox="0 0 16 16" >
                            <path d="M8 4.754a3.246 3.246 0 1 0 0 6.492 3.246 3.246 0 0 0 0-6.492zM5.754 8a2.246 2.246 0 1 1 4.492 0 2.246 2.246 0 0 1-4.492 0z"/>
                            <path d="M9.796 1.343c-.527-1.79-3.065-1.79-3.592 0l-.094.319a.873.873 0 0 1-1.255.52l-.292-.16c-1.64-.892-3.433.902-2.54 2.541l.159.292a.873.873 0 0 1-.52 1.255l-.319.094c-1.79.527-1.79 3.065 0 3.592l.319.094a.873.873 0 0 1 .52 1.255l-.16.292c-.892 1.64.901 3.434 2.541 2.54l.292-.159a.873.873 0 0 1 1.255.52l.094.319c.527 1.79 3.065 1.79 3.592 0l.094-.319a.873.873 0 0 1 1.255-.52l.292.16c1.64.893 3.434-.902 2.54-2.541l-.159-.292a.873.873 0 0 1 .52-1.255l.319-.094c1.79-.527 1.79-3.065 0-3.592l-.319-.094a.873.873 0 0 1-.52-1.255l.16-.292c.893-1.64-.902-3.433-2.541-2.54l-.292.159a.873.873 0 0 1-1.255-.52l-.094-.319zm-2.633.283c.246-.835 1.428-.835 1.674 0l.094.319a1.873 1.873 0 0 0 2.693 1.115l.291-.16c.764-.415 1.6.42 1.184 1.185l-.159.292a1.873 1.873 0 0 0 1.116 2.692l.318.094c.835.246.835 1.428 0 1.674l-.319.094a1.873 1.873 0 0 0-1.115 2.693l.16.291c.415.764-.42 1.6-1.185 1.184l-.291-.159a1.873 1.873 0 0 0-2.693 1.116l-.094.318c-.246.835-1.428.835-1.674 0l-.094-.319a1.873 1.873 0 0 0-2.692-1.115l-.292.16c-.764.415-1.6-.42-1.184-1.185l.159-.291A1.873 1.873 0 0 0 1.945 8.93l-.319-.094c-.835-.246-.835-1.428 0-1.674l.319-.094A1.873 1.873 0 0 0 3.06 4.377l-.16-.292c-.415-.764.42-1.6 1.185-1.184l.292.159a1.873 1.873 0 0 0 2.692-1.115l.094-.319z"/>
                        </svg>
                        <span className="fs-4 fw-bolder"></span>
                    </div>
                   {reduceSideBar === false ? <hr style={{width:"150px"}}></hr> : ''}
                    <ul className={reduceSideBar === false ? 'nav nav-pills flex-column mb-auto' : "nav  nav-pills nav-flush  mb-auto text-center"}>
                    
                        {<AdminSettingList  
                            onChangeSetting={onChangeSetting}
                            reduceSideBar={reduceSideBar}
                            activated = {setting}
                         />
                        }
                    </ul>
                    {reduceSideBar === false ? <hr style={{width:"150px"}}></hr> : ''}

                    <DropdownButton
                        className="text-center bg-none text-decoration-none"
                        variant="light" 
                        id="dropdown-basic"  
                        drop="up"
                        align="end"
                        title={ reduceSideBar === false ?  <><img src="https://github.com/mdo.png" alt="" width="32" height="32" className="rounded-circle me-2"></img>
                        <strong>{auth.user}</strong> 
                        <i className="bi bi-caret-up-fill"></i></> : <img src="https://github.com/mdo.png" alt="" width="32" height="32" className="rounded-circle "></img>}
                    >
                        <Dropdown.Item eventKey="1">Setting</Dropdown.Item>
                        <Dropdown.Item eventKey="2">Another action</Dropdown.Item>
                        <Dropdown.Item eventKey="3">Something else here</Dropdown.Item>
                        <Dropdown.Divider />
                        <Dropdown.Item eventKey="4">Profil</Dropdown.Item>
                    </DropdownButton>
                </div>

                <div className="flex-grow-1 border  border-start-0">
                    <Container className="pt-4 text-center">
                        
                        <Outlet/>

                    </Container>
                </div>

            </Container>
    </Style>
    </>)
}

function AdminSettingList(props){
    const onChangeSetting = props.onChangeSetting;
    const location = useLocation();
    const navigate = useNavigate();
    const listSvg = {

        home :  {
            name : "home",
            path : "home",
            icon : "bi bi-pie-chart"
        },
        dashboard :{
            name : "Contact",
            path : "contacts",
            icon : "bi bi-telephone-fill"
        },
        orders : {
            name: "Devis",
            path : "devis",
            icon : "bi bi-journals"
        },
        products :{ 
            name : "Users",
            path : "users",
            icon: "bi bi-people"
       },
        customers : { 
            name: "Add User",
            path : "adduser",
            icon: "bi bi-person-plus-fill"
        },
    }

    return (Object.keys(listSvg).map((item,index)=>{

        return (<li key={index}>
            <div className={props.reduceSideBar === false ? (location.pathname.split('/').includes(listSvg[item].path) ?'nav-link active' : "nav-link") : (location.pathname.split('/').includes(listSvg[item].path) ?'nav-link active  py-3 border-bottom rounded-0' : 'nav-link   py-3 border-bottom rounded-0')} 
                onClick={()=>{onChangeSetting(index); navigate(listSvg[item].path);}}
            >
                
                <i className={listSvg[item].icon} style={{fontSize:props.reduceSideBar === false ? 16 : 20}}></i>

                {props.reduceSideBar === false ?  ' '+listSvg[item].name : ' '}
            </div>
        </li>)
    }));

}

