import React, { useState, useEffect } from "react";
import Logo from "../../assets/img/logo.png"
import SiForm from "./SiForm";
import PompageForm from "./PompageForm";
import SummaryForm from "./SummaryForm"
import Form from 'react-bootstrap/Form'
import {Link} from "react-router-dom";
import ProgressBar from 'react-bootstrap/ProgressBar';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';
import { PublicService } from "../../services/public.service";
import parse from 'html-react-parser';
var client = {
  information : {},
  projects : []
};


export default function Devis(props) {
  const [validated, setValidated] = useState(false);
  const [formState, setFormState] = useState(0);
  const [sendStatus, setSendStatus] = useState(undefined)
  const handleProjectInformation = (e) => {
      client.projects[0][e.target.title] = e.target.value 
  }
  const handleCaptcha = (captcha)=>{
    client.captcha = captcha;
  }
  
  useEffect (() => {
    
    if(validated ){
      if(formState === 0 
        && (client.information["lastName"] !==  "") && client.information["lastName"]
        && (client.information["firstName"] !==  "") && client.information["firstName"]
        && (client.information["phone"] !==  "") && client.information["phone"] ){
          setValidated(false);
          setFormState(()=> formState +1);
          if(client.projects.length === 0)
            client.projects.push({})

      }else if ( formState === 1
          && client.projects[0].projectSector && client.projects.projectSector !== ""
          && client.projects[0].projectType && client.projects.projectType !== ""
          && client.projects[0].country && client.projects.country !== ""
          && client.projects[0].city && client.projects.city !== "") {
            setValidated(false);
            setFormState(()=> formState +1);
      }else if (formState >= 2){
        setValidated(false);
        setFormState(()=> formState +1);
      }
    }
    if(formState === 5){
      PublicService.setNewDevis(client).then(res=>{
        if(res && res.message === "captcha error"){
          setFormState(4)
          return;
        }
        if(res && res.success === 200){
          setSendStatus(true)
        }else{
          setSendStatus(false)
        }  
      })
    }
  },[validated,formState])

  const handleEndInput = (e) => {
    client.information[e.target.title] = e.target.value;
  }

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    if (form.checkValidity() === false) {
      event.stopPropagation();
    }
    setValidated(true);
    setTimeout(function(){form.classList.remove('was-validated');setValidated(false);}, 1050);
  };

  return (
    <>  
        <div className="container-sm py-5 ">
          <div className="py-5 text-center ">
            <img className="d-block mx-auto mb-4" src={Logo} alt="" width="192" height="165"></img>
            <h2 className="text-primary " style={{textShadow: "0px 5px 5px #ffc107",color:"#318af2"}} >Devis gratuit</h2>
             <span className="lead ">{ formState < 3 ? "Remplissez le formulaire soigneusement, toutes les données sont nécéssaire pour effectuer un devis aproximatif" : 
             formState === 3 ? "Verifiez vos données avant de valider le formulaire." : " "}</span> 
          </div>
          <Form className="needs-validation" validated={validated} onSubmit={handleSubmit} noValidate >
            <div className="row g-3 ">
             
              { formState === 0 ? <ClientInformation handleEndInput={handleEndInput} /> : ''}
              { formState === 1 ? <ProjectInformation handleProjectInformation={handleProjectInformation} /> : ''}
              { formState === 2 ? <RemarkInformation handleProjectInformation={handleProjectInformation} /> : ''}
              { formState === 3 ? <SummaryForm client={client}/>: ''}
              { formState === 4 ? <Captcha handleCaptcha={handleCaptcha}/> : ''}
              { formState === 5 ? <SendCheck status={sendStatus} /> : ''}
              
              <div className="d-grid gap-2 d-md-flex justify-content-md-center">
              { formState <=  4 && formState >= 1 ? <button className="btn btn-primary " onClick={(e)=>{e.preventDefault();setFormState(()=> formState - 1) ;}} > Retour </button>  : ""}  
              { formState !== 5 ? <button className="btn btn-primary " >{formState === 0 || formState === 1? "Suivant" : "Valider"}</button> : ''}
              </div>
            </div>
          </Form>
          { formState <= 3 ?<ProgressForm state={formState}/> : ''}
        </div>
    </>
  );
}

function ClientInformation (props){
  const handleEndInput = props.handleEndInput;
  return (<>
              <h4 className="mb-3 text-center">Coordonnées</h4>
              
              <div className="col-sm-4">
                <label htmlFor="nom_societe" className="form-label text-dark">Nom de la société</label>
                <input type="text" className="form-control" title="societyName" placeholder=""  defaultValue = {"" || client.information.societyName} onBlur={(e)=>handleEndInput(e)} ></input>
                <div className="invalid-feedback">
                  Le nom de la société ne peut pas etre vide.
                </div>
              </div>
      
              <div className="col-sm-4">
                <label htmlFor="Nom" className="form-label text-dark">Nom*</label>
                <input type="text" className="form-control" title="firstName" placeholder="" defaultValue = {"" || client.information.firstName} onBlur={(e)=>handleEndInput(e)}  required></input>
                <div className="invalid-feedback">
                  Le Nom ne peut pas etre vide.
                </div>
              </div>
      
              <div className="col-sm-4">
                <label htmlFor="Prenom" className="form-label text-dark">Prenom*</label>
                <input type="text" className="form-control" title="lastName" placeholder="" defaultValue = {"" || client.information.lastName} onBlur={(e)=>handleEndInput(e)}  required></input>
                <div className="invalid-feedback">
                  Le prenom ne peut pas etre vide.
                </div>
              </div>
      
              <div className="col-5">
                <label htmlFor="tel" className="form-label text-dark">Téléphone* </label>
                <input type="tel" className="form-control" title="phone" placeholder="" defaultValue ={"" || client.information.phone} onBlur={(e)=>handleEndInput(e)} required></input>
                <div className="invalid-feedback">
                  Entrer un numero valide.
                </div>
              </div>
              
              <div className="col-7">
                <label htmlFor="email" className="form-label text-dark">Email</label>
                <input type="email" className="form-control" title="email" placeholder="" defaultValue ={"" || client.information.email} onBlur={(e)=>handleEndInput(e)} ></input>
                <div className="invalid-feedback">
                  Entrer un mail valide.
                </div>
              </div>

              <hr className="my-4"></hr>
        </>
          
  );
}

function ProgressForm(props) {
  return (
    <>
    <Container className="py-5">
      <Row className="justify-content-md-center">
        <Col xs lg="6">
            <ProgressBar animated  striped variant="success" label={`${props.state}/3`} now={props.state * 100/3 || 0}  />
        </Col>
      </Row>
    </Container>
    </>
  );
}

function SendCheck (props) {
  return (
    <>
    <Container className="py-5">
      <Row className="justify-content-center">
        {props.status === undefined ?
          <Col xs={12} className="text-center">
          <Spinner  animation="border" className="text-center" variant="success" /> 
          </Col>
        : ''}
        {props.status !== undefined ?
          <Col xs={12} className="text-center">
          {props.status === false ? <i className="bi bi-x-circle text-danger" style={{fontSize: "40px"}} ></i>  : <i className="bi bi-check-circle text-success" style={{fontSize: "40px"}} ></i> }
          {props.status === false ? <h3 className="text-danger text-center">Formulaire non envoyé!</h3> : <h3 className="text-success text-center">Demande envoyé avec succes</h3> }
          </Col>
        : ''}
        <Col lg="12"  className="text-center pt-5 ">
        <Link to="/"className="btn btn-outline-primary"  >Acceuil</Link>
        </Col>
      </Row>
    </Container>
    </>
  );
}

function ProjectInformation (props){
  const handleProjectInformation = props.handleProjectInformation;

  const [visibleRefSteg,setVisibleRefSteg] = useState(true);
  const [visibleNoProject, setvisibleNoProject] = useState(true);
  const [currentProjectType, setCurrentProjectType] = useState(client.projects[0].projectType);
  const [consommation,setConsommation] = useState(client.projects[0].consommation)


  useEffect(()=>{
    visibilityProjectType(currentProjectType)
  },[currentProjectType])
  
  const handleConsommation = (data) => {
    client.projects[0].consommation = data;
    setConsommation(data) 
  }

  const handleProjectType = (e) => {
    setCurrentProjectType(e.target.value)
    handleProjectInformation(e);
  }

  const visibilityProjectType = (val)=>{

    if (val === undefined || val === ""){
      setvisibleNoProject(true)
    } else {
      setvisibleNoProject(false)
    }

    if (val === 'CR'){
      setVisibleRefSteg(false)
    }else{
      setVisibleRefSteg(true)
    } 
  }

  return(
      <>
        <h4 className="mb-3 text-center">Projets</h4> 
        <div className="col-12" >
          <div  className="row">

            <div className="col-md-5">
              <label htmlFor="projectSector" className="form-label">Secteurs</label>
              <Form.Select className="form-select" value={ client.projects[0].projectSector } title="projectSector" onChange={(e=>{handleProjectInformation(e) })} required>
                <option value="" >Selectionner le secteur</option>
                <option value="privee" >Privée</option>
                <option value="public">Public</option>
              </Form.Select>
              <div className="invalid-feedback">
                Secteur est requis.
              </div>
            </div>
       
            <div className="col-md-4">
              <label htmlFor="projectType" className="form-label">Type de projet</label>
              <Form.Select className="form-select" value={client.projects[0].projectType}  onChange={handleProjectType} title="projectType" required>
                <option  value="" >Selectionner le type de projet</option>
                <option value="SI">Site isolé</option>
                <option value="CR">Connecté au reseaux</option>
                <option value="PS">Pompage solaire</option>
              </Form.Select>
              <div className="invalid-feedback">
                Type de projet est requis.
              </div>
            </div>
          
            <div className="col-md-3">
              <label htmlFor="stegRef" className="form-label">Référence de Steg</label>
              <input type="text" className="form-control" onChange={(e=>{handleProjectInformation(e) })} title="stegRef" defaultValue = {"" ||client.projects[0].stegRef} placeholder=""  disabled={ visibleRefSteg}></input>
              <div className="invalid-feedback">
                  La référence de steg est requis.
              </div>
            </div>
          
            <div className="col-12">
              <label htmlFor="projectLocationType" className="form-label">Lieu d'installation</label>
              <input type="text" className="form-control" onChange={(e=>{handleProjectInformation(e) })} title="projectLocationType" defaultValue = {"" ||client.projects[0].projectLocationType } list="datalistOptions" placeholder="Commercial, Domestique, Agricol, industriel" disabled={visibleNoProject}></input>
              <datalist id="datalistOptions2">
                  <option value="Commercial" ></option>
                  <option value="Domestique"></option>
                  <option value="Agricol"></option>
                  <option value="industriel"></option>
                </datalist>
            </div>
              
            <div className="col-12">
              <label htmlFor="street" className="form-label">Address</label>
              <input type="text" className="form-control" onChange={(e=>{handleProjectInformation(e) })} title="street"  defaultValue = {"" ||client.projects[0].street} placeholder="" disabled={ visibleNoProject}></input>
              <div className="invalid-feedback">
                L'adresse est obligatoire.
              </div>
            </div>
          
            <div className="col-md-5">
              <label htmlFor="country" className="form-label">Pays</label>
              <input className="form-control" onChange={(e=>{handleProjectInformation(e) })} list="datalistOptions" title="country" defaultValue = {"" ||client.projects[0].country } placeholder="" disabled={visibleNoProject} required ></input>
                <datalist id="datalistOptions">
                  <option value="Tunisie" ></option>
                  <option value="Algerie"></option>
                  <option value="Maroc"></option>
                  <option value="Lybie"></option>
                  <option value="egypt"></option>
                </datalist>
              <div className="invalid-feedback">
                Le pays est requis.
              </div>
            </div>
          
            <div className="col-md-4">
              <label htmlFor="city" className="form-label">Ville</label>
              <input className="form-control" onChange={(e=>{handleProjectInformation(e) })} list="datalistOptions1" title="city" defaultValue = {"" ||client.projects[0].city } placeholder="" disabled={visibleNoProject} required></input>
                <datalist id="datalistOptions1">
                  <option defaultValue="Djerba" ></option>
                  <option defaultValue="Mednine"></option>
                  <option defaultValue="Nabeul"></option>
                  <option defaultValue="Guellala"></option>
                  <option defaultValue="tataouine"></option>
                </datalist>
              <div className="invalid-feedback">
                Please provide a valid state.
              </div>
            </div>
          
            <div className="col-md-3">
              <label htmlFor="zip" className="form-label">Code postale</label>
              <input type="text" onChange={(e=>{handleProjectInformation(e) })} className="form-control" title="zip" defaultValue = {"" ||client.projects[0].zip } placeholder="" disabled={ visibleNoProject} ></input>
              <div className="invalid-feedback">
                Code postale est requis
              </div>
            </div>

          </div>
        </div>

        <hr className="my-4"></hr>

        { currentProjectType === 'SI' ? <SiForm consommation={consommation === undefined ? [] : consommation} handleConsommation={items => {handleConsommation(items)}}/> : '' }
        { currentProjectType === 'PS' ? <PompageForm consommation={consommation === undefined ? [] : consommation} handleConsommation={items => {handleConsommation(items)}} /> : ''}
        
      </>
  );
}

function RemarkInformation (props){
  const handleProjectInformation = props.handleProjectInformation;
  return (
      <>
        <h4 className="mb-3 text-center">Remarque</h4> 
        <div className="mb-3">
          <label htmlFor="remarque" className="form-label">Information complémenaire de projet </label>
          <textarea className="form-control" onBlur={(e=>{handleProjectInformation(e) })}  defaultValue={client.projects[0].remark}  title="remark" rows="3"  ></textarea>
        </div>
      </>
  );
}

function Captcha (props) {
  const [svg,setSvg] = useState(<div></div>)
  useEffect(()=>{
    PublicService.getCaptcha().then((res)=>{setSvg(res)})
  },[])

  return (
  <>
    <h4 className="mb-3 text-center">Captcha</h4> 
    <div className="mb-5">
      <div className="row justify-content-center">
        <div className='col-auto text-center'> 
          {parse(svg + "")}
          <br></br>
          <input onChange={(e)=>props.handleCaptcha(e.target.value)}></input>
        </div>
      </div>
    </div>
  </>)
}