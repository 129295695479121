import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Container, Form, InputGroup, Row } from "react-bootstrap";
import { PublicService } from "../../services/public.service";
import CaptchaFormulaire from "../unstructuredComponents/CaptchaFormulaire";
import SendCheck from "../unstructuredComponents/SendCheck";


export default function CandidatureSpontanee(props) {


  return (
    <Container className=" overflow-hidden "  fluid>
      <Row className="justify-content-sm-center">
        <Col md={8}>
          <h1 className="mb-4 text-center break-word ">Candidature Spontanée</h1>

          <br></br> 

          <p className="mb-4 pb-2 text-dark">
            Universal Energy est une entreprise spécialisée dans les énergies renouvelables, 
            notamment l'énergie solaire. Nous nous engageons à fournir des solutions énergétiques 
            innovantes qui contribuent à un monde plus propre et plus respectueux de l'environnement.
          </p>

          <br></br> 
          
          <p className="mb-4 pb-2 text-dark fw-bold fs-4">
            Notre mission
          </p>

          <p className="mb-4 pb-2 text-dark">
          Notre mission est de contribuer à la transition énergétique en proposant des solutions solaires abordables et efficaces. 
          Nous nous engageons à accompagner nos clients tout au long de leur projet, de l'étude de faisabilité à l'installation et 
          la maintenance de leurs panneaux solaires.
          </p>

          <br></br> 

          <p className="mb-4 pb-2 text-dark fw-bold fs-4">
            Nos valeurs
          </p>

          <p className="mb-4 pb-2 text-dark">
          Nous sommes animés par les valeurs suivantes :

          <br></br><br></br>

          <strong className="fw-bold">L'innovation :</strong> nous nous engageons à proposer des solutions solaires innovantes et performantes.
          
          <br></br><br></br>

          <strong className="fw-bold">La durabilité : </strong>nous sommes convaincus que les énergies renouvelables sont la solution pour un avenir énergétique durable.
          
          <br></br><br></br>
          
          <strong className="fw-bold">L'engagement :</strong> nous nous engageons à accompagner nos clients dans leur projet solaire.
          </p>
          
          <br></br> 

          <p className="mb-4 pb-2 text-dark fw-bold fs-4">
            Nos activités
          </p>

          <p className="mb-4 pb-2 text-dark">
            Nous proposons une large gamme de services liés à l'énergie solaire, notamment :

            <br></br><br></br>

            <strong className="fw-bold">L'étude de faisabilité : </strong>nous étudions vos besoins et vos possibilités pour vous proposer une solution solaire adaptée.

            <br></br><br></br>
            
            <strong className="fw-bold">L'installation : </strong> nous installons vos panneaux solaires dans les règles de l'art, en respectant les normes de sécurité.

            <br></br><br></br>
            
            <strong className="fw-bold">La maintenance : </strong> nous assurons la maintenance de vos panneaux solaires pour garantir leur bon fonctionnement.
            Notre équipe

            <br></br><br></br>

            Notre équipe est composée de professionnels expérimentés et passionnés par les énergies renouvelables.
          </p> 

          <br></br> 

          <p className="mb-4 pb-2 text-dark fw-bold fs-4">
            Postulez dès aujourd'hui
          </p>

          <p className="mb-4 pb-2 text-dark">
          Si vous êtes motivé(e) par le challenge de contribuer à un avenir énergétique durable, 
          nous vous invitons à déposer votre CV. Nous recherchons des personnes passionnées par 
          les énergies renouvelables, avec un esprit d'équipe et un sens du service client.

          <br></br><br></br>
          Pour postuler, merci d'envoyer votre CV et votre lettre de motivation ci-dessous
          <br></br><br></br>
          Nous vous remercions de votre intérêt pour Universal Energy.
          </p>

          <br></br><br></br>

        </Col>
      </Row>

      <h1 className="mb-4 text-center break-word fs-3 ">Formulaire de candidature</h1>
      
      <br></br><br></br>
      <CandidatureFormulaire/>
    
    </Container>
    );
}


function CandidatureFormulaire(){
  const [candidature, setCandidature ] = useState({})
  const [validated, setValidated] = useState(false);
  const [captcha,setCaptcha] = useState(false);
  const [submit,setSubmit] = useState(false);
  const [sendStatus, setSendStatus] = useState(undefined)

  const getBase64 = async (file) => {
    return new Promise(resolve => {
      let baseURL = "";

      let reader = new FileReader();

      reader.readAsDataURL(file);

      reader.onload = () => {
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  };
  const onFileChange =async  (e) => {
    if(e.target.id === "cv" || e.target.id === "lettreDeMotivation"){
      const file = {file:await getBase64 (e.target.files[0]),name:e.target.files[0].name,type:e.target.files[0].type,size:e.target.files[0].size}
      if(bytesToMb(file.size ) <= 1){
        setCandidature((prev)=>({...prev, [e.target.id]:file}));
        document.getElementById(e.target.id+"Size").className += " bg-success"
      }else{
        document.getElementById(e.target.id+"Size").className += " bg-danger"
      }
    }else {
      setCandidature((prev)=>({...prev, [e.target.id]:e.target.value}));
    }
  };

  const bytesToMb = (bytes)=>{
      bytes = (bytes / 1048576).toFixed(2);
      return bytes;
  }

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    if(form.checkValidity() === true){
      setCaptcha(true);
    }
    if (form.checkValidity() === false) {
      event.stopPropagation();
    }
    setValidated(true);
  };

  const handleCaptcha = (captcha)=>{
    setCandidature((prev)=>({...prev,captcha:captcha}));
  }

  useEffect(()=>{
    if(submit=== true){
      PublicService.setNewCandidature(candidature).then(res=>{
        if(res && res.message === "captchaError"){
          setSubmit(false);
          setCaptcha(true);
          return;
        }
        if(res && res.success === 200){
          setSendStatus(true)
        }else{
          setSendStatus(false);
          setTimeout(()=>{setCaptcha(true);setSubmit(false);}, 4050);
        }  
      })

    }
  },[submit])

  if(submit){
    return(
      <Container>
        <SendCheck status={sendStatus} /> 
      </Container>
    )
  }
  if(captcha){
    return( 
      <Container fluid>
        <CaptchaFormulaire handleCaptcha={handleCaptcha}/>

        <Row className="justify-content-center">
          <Col xs="auto">
          <Button className="btn btn-primary rounded-pill py-3 px-5" onClick={()=>{ setCaptcha(false);setSubmit(true)}} >Valider</Button> 
          </Col>
        </Row>

      </Container>
    )
  }
  return(
    <Container fluid>
    <Form noValidate validated={validated} onSubmit={handleSubmit}>
      <Row className="justify-content-sm-center">

        <Col  md={{ span: 3, offset: 0 }} sm={{ span: 6, offset: 0 }}>
        <InputGroup className="mb-3">
          <InputGroup.Text className="text-dark">Nom</InputGroup.Text>
          <Form.Control id="nom" defaultValue = {"" || candidature.nom}   onBlur={onFileChange} required />
          <Form.Control.Feedback type="invalid">
                Merci de renseigner votre nom.
          </Form.Control.Feedback>
        </InputGroup>
        </Col>

        <Col md={{ span: 3, offset: 0 }} sm={{ span: 6, offset: 0 }}>
        <InputGroup className="mb-3">
          <InputGroup.Text className=" text-dark">Prenom</InputGroup.Text>
          <Form.Control id="prenom" defaultValue = {"" || candidature.prenom}   onBlur={onFileChange} required/>
          <Form.Control.Feedback type="invalid">
                Merci de renseigner votre prenom.
          </Form.Control.Feedback>
        </InputGroup>
        </Col>

      </Row>

      <Row className="justify-content-sm-center">

        <Col md={{ span: 3, offset: 0 }} sm={{ span: 6, offset: 0 }}>
        <InputGroup className="mb-3">
          <InputGroup.Text className=" text-dark">Email</InputGroup.Text>
          <Form.Control id="email" defaultValue = {"" || candidature.email}   onBlur={onFileChange} required/>
          <Form.Control.Feedback type="invalid">
                Merci de renseigner votre mail.
          </Form.Control.Feedback>
        </InputGroup>
        </Col>

        <Col md={{ span: 3, offset: 0 }} sm={{ span: 6, offset: 0 }}>
        <InputGroup className="mb-3">
          <InputGroup.Text className=" text-dark">Tel</InputGroup.Text>
          <Form.Control id="telephone" defaultValue = {"" || candidature.telephone}   onBlur={onFileChange} required/>
          <Form.Control.Feedback type="invalid">
                Merci de renseigner votre numéro de téléphone.
          </Form.Control.Feedback>
        </InputGroup>
        </Col>

      </Row>

      
      <Row className="justify-content-sm-center">
        
      <Col md={{ span: 3, offset: 0 }} sm={{ span: 6, offset: 0 }}>
          <InputGroup  className="mb-3">
            <InputGroup.Text className="text-dark p-0"  data-bs-toggle="tooltip" data-bs-placement="bottom" data-bs-title="Selectionner un cv en pdf">
              <label htmlFor="cv"  style={{padding:".375rem .75rem"}}>
                <i className="bi bi-file-earmark-arrow-down"></i>
              </label>
            </InputGroup.Text>
            <Form.Control aria-label="cv" className="text-center bg-white" placeholder={ candidature?.cv?.name  || "cv"} disabled/>
            <input className="bg-white text-dark" onChange={onFileChange }  id="cv" style={{ display: "none" }} type="file" accept=".pdf" required />
            <InputGroup.Text id="cvSize" className="text-dark ">1mb</InputGroup.Text>
            <Form.Control.Feedback type="invalid">
                Merci d'importer votre cv'.
          </Form.Control.Feedback>
          </InputGroup >
        </Col>

        <Col md={{ span: 3, offset: 0 }} sm={{ span: 6, offset: 0 }}>
          <InputGroup  className="mb-3" >
            <InputGroup.Text className="text-dark p-0" >
              <label htmlFor="lettreDeMotivation" style={{padding:".375rem .75rem"}} >
                <i className="bi bi-file-earmark-arrow-down" ></i>
              </label>
            </InputGroup.Text>
            <Form.Control aria-label="LettreDeMotivation" className="text-center bg-white" placeholder={ candidature?.lettreDeMotivation?.name ||  "Lettre de motivation" } disabled/>
            <input className="bg-white text-dark" onChange={onFileChange}  id="lettreDeMotivation" style={{ display: "none" }} type="file" accept=".pdf"  />
            <InputGroup.Text id="lettreDeMotivationSize" className="text-dark " >1mb</InputGroup.Text>
          </InputGroup >
        </Col>

      </Row>

      <br></br><br></br>

      <Row className="justify-content-center">
        <Col xs="auto">
        <Button className="btn btn-primary rounded-pill py-3 px-5" type="submit" >Envoyer</Button> 
        </Col>
      </Row>

    </Form>
  </Container>
  )
}