/*const languages = [
  { code: 'en', name: 'English'},
  { code: 'ar', name: 'عربي'},
  { code: 'fr', name: 'Français'}
]*/

const translations = {
    'fr': {
      'location': '12 Av Farhat Hached, 4116 Midoun-Djerba, Tunisie',
      'availability': 'Lundi - Vendredi : 08h - 17h | Samedi : 08h - 14h',
      'home':'Acceuil',
      'about':'A propos',
      'service':'Service',
      'project':'Projets',
      'signin':'Se connecter',
      'recruitment':'Recrutement',
      'devis':'Devis'
    },
    'ar': {
        'location': '12 شارع فرحات حشاد، 4116 ميدون-جربة، تونس',
        'availability': '',
        'home':'',
        'about':'',
        'service':'',
        'project':'',
        'signin':''
    },
      'en': {
      'location': '12 Av Farhat Hached, 4116 Midoun-Djerba, Tunisie',
      'availability': 'Monday - Friday : 8am - 5pm | Sunday : 8am - 2pm',
      'home':'Home',
      'about':'About',
      'service':'Service',
      'project':'Projects',
      'signin':'Sign in',
      'recruitment':'Recruitment',
      'devis':'Quote'
    }
  }
  
  export default function getTranslation  (lang, text)  {
    return translations[lang][text];
  }