import React from "react";
import Valeur from "./Valeur"
import Map from "./Map";

export default function Home(props) {
  return (
    <> 
     <Map />
    <Valeur/>
    </> 
    );
}
