import React from "react";
import Upheader from "./component/header/Upheader"
import Header from "./component/header/Header"
import Footer from "./component/footer/Footer";
import Home from "./component/home/Home"
import About from "./component/about/About"
import Project from "./component/project/Project";
import Contact from "./component/contact/Contact";
import SignIn from "./component/sign/SignIn";
import Devis from "./component/devis/Devis";
import Recruitment from "./component/recruitment/Recruitment"
import Services from "./component/services/Services"
import ServicesList from "./component/services/ServicesList";
import PanneauxSolaires from "./component/services/servicesList/PanneauxSolaires";
import DownloadFiles from "./component/download/DownloadFiles";
import RequireAuth from "./services/authentication/RequireAuth";
import Dashboard from "./component/sign/user/Dashboard";
import SignInForm from "./component/sign/SignInForm";
import { AuthProvider } from "./services/authentication/useAuth";
import Documents from "./component/sign/user/Documents";
import Profil from "./component/sign/user/Profil";
import PlantDetails from "./component/sign/user/PlantDetails";
import Admin from "./component/sign/user/admin/Admin";
import ClientDashboard from "./component/sign/user/client/ClientDashboard";
import AdminDevis from "./component/sign/user/admin/Devis/AdminDevis";
import AdminUser from "./component/sign/user/admin/User/AdminUser";
import AdminContact from "./component/sign/user/admin/Contact/AdminContact";
import AdminAddUser from "./component/sign/user/admin/User/AdminAddUser";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useLocation
} from "react-router-dom";


// This site has 3 pages, all of which are rendered
// dynamically in the browser (not server rendered).
//
// Although the page does not ever refresh, notice how
// React Router keeps the URL up to date as you navigate
// through the site. This preserves the browser history,
// making sure things like the back button and bookmarks
// work properly.

export default class App extends React.Component {
  constructor(props) {    
    super(props);    
    this.state = {
      lang:'fr'
    };  
  }
  render(){
    return (
      <AuthProvider>
      <Router>
      <div>
        <Upheader lang={this.state.lang}/>
        <Header lang={this.state.lang}/>
        

        <Routes>
          <Route exact path="/" element={<Home/> }>
          </Route>

          <Route path="/about" element={<About />}>
          </Route>
          
          <Route path="/service" element={<Services />}>
            <Route  index element={<ServicesList />}></Route>
            <Route  path="solarPanels" element={<PanneauxSolaires />}></Route>
            <Route path="*" element={<Construction/>}></Route>
          </Route>

          <Route path="/project" element={<Project />}>
          </Route>

          <Route path="/recruitment" element={<Recruitment />}>
          </Route>

          <Route path="/devis" element={<Devis />}>
          </Route>

          <Route path="/user" element={<SignIn />}>
            <Route index element={<RequireAuth> <Dashboard/></RequireAuth>}></Route>
            
            <Route path="admin" element={<RequireAuth> <Admin/></RequireAuth>}>
              <Route path="devis" element={<RequireAuth> <AdminDevis/></RequireAuth>}></Route>
              <Route path="users" element={<RequireAuth> <AdminUser/></RequireAuth>}></Route>
              <Route path="contacts" element={<RequireAuth> <AdminContact/></RequireAuth>}></Route>
              <Route path="adduser" element={<RequireAuth> <AdminAddUser/></RequireAuth>}></Route>

              <Route path="*" element={<NotFound/>}></Route>
            </Route>

            <Route path="dashboard" element={<RequireAuth> <ClientDashboard/></RequireAuth>}></Route>
            <Route path="plantDetails/:provider/:plantId" element={<RequireAuth> <PlantDetails/></RequireAuth>}></Route>
            <Route path="profil" element={<RequireAuth> <Profil/></RequireAuth>}></Route>
            <Route path="documents" element={<RequireAuth> <Documents/></RequireAuth>}></Route>
              
            

            <Route path="signin" element={<SignInForm/>}></Route>
            <Route path="*" element={<NotFound/>}></Route> 
          </Route>

          

          <Route path="/contact" element={<Contact/>}>
          </Route>

          <Route path="/download" element={<DownloadFiles/>}>
          </Route>

          <Route path="*" element={<NotFound/>}>
            
          </Route>
        </Routes>

        <Footer/>
        </div>
    </Router>
      
    </AuthProvider>
    
    );
  }
}

function NotFound() {
  return (
    <div className="container-xxl py-5 ">
        <div className="container text-center">
            <div className="row justify-content-center">
                <div className="col-lg-6">
                    <i className="bi bi-exclamation-triangle display-1 text-primary"></i>
                    <h1 className="display-1">404</h1>
                    <h1 className="mb-4">Page Not Found</h1>
                    <p className="mb-4">We’re sorry, the page you have looked for does not exist in our website! Maybe go to our home page or try to use a search?</p>
                    <Link to="/"><h3 className="text-danger rounded-pill py-3 px-5" >Go Back To Home</h3></Link>
                </div>
            </div>
        </div>
    </div>
  );
}

function Construction() {
  let location = useLocation();
  return (
    <div className="container-xxl py-5 ">
        <div className="container text-center">
            <div className="row justify-content-center">
                <div className="col-lg-6">
                    <i className="bi bi-exclamation-triangle display-1 text-primary"></i>
                    <h1 className="display-1">Sorry</h1>
                    <h1 className="mb-4">Page In Construction</h1>
                    <p className="mb-4">We’re sorry, the page you have looked is in construction! Maybe go to our home page or go back to the last page?</p>
                    <Link to={"/"+location.pathname.split('/').splice(1,location.pathname.split('/').length-2).join('/')}><h3 className="text-danger rounded-pill py-3 px-5" >Go Back</h3></Link>
                </div>
            </div>
        </div>
    </div>
  );
}