import React from "react";
import featurePicture from "../../assets/img/feature.jpg"
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";


export default function Valeur(props) {
  return (
    <div className="container-fluid bg-light overflow-hidden my-5 px-lg-0" id="feature2_component">
    <div className="container feature px-lg-0">
        <div className="row g-0 mx-lg-0">
            <div className="col-lg-6 feature-text p-5 py-5 ">
                <div className="p-lg-5 ps-lg-0">
                    <h6 className="text-primary text-center fs-6">Facture à zéro, énergie à fond. prêt pour le changement ?</h6>
                    <h1 className="mb-4 text-center break-word ">Etude de projet, Installation et une garantie de 2 ans aprés l'installation</h1>
                    <p className="mb-4 pb-2 text-dark">
                        <br></br> 
                        Universal energy vous guide vers l'autosuffisance énergétique. 2 ans de garantie et de services gratuits après la réalisation de votre installation photovoltaïque.
Contrôlez et suivez votre production à distance avec nos outils intelligents.
Bienvenue dans le future de l’énergie avec confiance.
                        
                    Nous sommes là pour vous aider !</p>
                    <div className="row g-4">
                        <div className="col-6">
                            <div className="row justify-content-center">
                                <div className="col-3 btn-square bg-primary rounded-circle ">
                                    <i className="fa fa-check text-white"></i>
                                </div>
                                <div className="col-9">
                                    <p className="mb-0">Garantie de service</p>
                                    <h6 className="mb-0">2 ans</h6>
                                </div>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="row justify-content-center">
                                <div className="col-3 btn-square bg-primary rounded-circle">
                                    <i className="fa fa-check text-white"></i>
                                </div>
                                <div className="col-9">
                                    <p className="mb-0">Control et monotoring</p>
                                    <h6 className="mb-0">illimité</h6>
                                </div>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="row justify-content-center">
                                <div className="col-3 btn-square bg-primary rounded-circle">
                                    <i className="fa fa-check text-white" ></i>
                                </div>
                                <div className="col-9">
                                    <p className="mb-0">Mobilité</p>
                                    <h6 className="mb-0">Tunisie et Afrique</h6>
                                </div>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="row justify-content-center">
                                <div className="col-3 btn-square bg-primary rounded-circle">
                                    <i className="fa fa-check text-white"></i>
                                </div>
                                <div className="col-9">
                                    <p className="mb-0">SAV</p>
                                    <h6 className="mb-0">réactif</h6>
                                </div>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="row justify-content-center">
                                <div className="col-3 btn-square bg-primary rounded-circle ">
                                    <i className="fa fa-check text-white"></i>
                                </div>
                                <div className="col-9">
                                    <p className="mb-0">Etude de projet</p>
                                    <h6 className="mb-0">Gratuit</h6>
                                </div>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="row justify-content-center">
                                <div className="col-3 btn-square bg-primary rounded-circle ">
                                    <i className="fa fa-check text-white"></i>
                                </div>
                                <div className="col-9">
                                    <p className="mb-0">Demande de devis</p>
                                    <h6 className="mb-0">Gratuit</h6>
                                </div>
                            </div>
                        </div>
                        <div style={{height:"60px"}}></div>
                        <div className="col-12">
                            <div className="d-flex justify-content-center">
                                <Link  to="/contact">
                                    <Button variant="primary" className="w-30"  size="lg">
                                        Pour plus d'informations
                                    </Button>
                                </Link >
                            </div>
                        </div>


                    </div>
                </div>
            </div>
            <div className="col-lg-6 pe-lg-0 " style={{minHeight: '400px'}}>
                <div className="position-relative h-100">
                    <img className="position-absolute img-fluid w-100 h-100" src={featurePicture} style={{objectFit: 'cover'}} alt=""></img>
                </div>
            </div>
        </div>
    </div>
</div>
    );
}
