import React, {useEffect, useState} from "react";
import logo from "../../assets/img/logo.png"
import getTranslation from "./HeaderLang";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import ConnectionButtonHeader, {ConnectionButtonOffCanvas} from "../sign/user/ConnectionButton";


import {
  NavLink as BaseNavLink
} from "react-router-dom";

// This site has 3 pages, all of which are rendered
// dynamically in the browser (not server rendered).
//
// Although the page does not ever refresh, notice how
// React Router keeps the URL up to date as you navigate
// through the site. This preserves the browser history,
// making sure things like the back button and bookmarks
// work properly.
const NavLink = React.forwardRef(
  ({ activeClassName,...props}, ref) => {
    return (
      <BaseNavLink
        ref={ref}
        {...props}
        className={({ isActive }) =>
          [
            props.className,
            isActive ? "active" : null,
          ]
            .filter(Boolean)
            .join(" ")
        }
      />
    );
  }
);

export default function Header(props) {
  const [show,setShow] = useState(false);
 
  const closeOffCanvas = () => {setShow(false)}

  const openOffCanvas = () =>  show === false ? setShow(true) : setShow(false) ;

  const checkIfClickedOutside = e => {
    if (e.srcElement.className !== "offcanvas-body" && e.srcElement.className !== "navbar-toggler-icon" && e.srcElement.title !== "userDropdown" ) {
      closeOffCanvas()
    }
  }

  useEffect(() => {    
    document.addEventListener("mousedown", checkIfClickedOutside,false)

    return () => { 
      document.removeEventListener("mousedown", checkIfClickedOutside,false)
    }
  })

  return (

      <div >
        {['xl'].map((expand) => (
        <Navbar key={expand} bg="white"  expand={expand} variant="light" className="sticky-top p-0"  >
          <Container fluid>
          <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} className="me-auto" onClick={openOffCanvas}/>

            <Navbar.Brand to="/" className="navbar-brand d-flex align-items-center px-sm-4 px-lg-5 me-auto" >
              <h2 className="m-0 text-primary " style={{fontFamily: "'Abril Fatface', serif"}} id="brand">Universal Energy<img className="d-none d-sm-inline-block" id="brandi" src={logo} alt='' style={{width:'70px',height:'50px'}}></img></h2>
            </Navbar.Brand>
            <Nav className="d-xl-flex  d-none navbar-nav d-auto p-0 p-lg-0 item-shadow" >
                <NavLink to="/" className="nav-item nav-link" >{getTranslation(props.lang,'home')}</NavLink>
                <NavLink to="/about" className="nav-item text-nowrap nav-link" >{getTranslation(props.lang,'about')}</NavLink>
                <NavLink to="/service" className="nav-item nav-link">{getTranslation(props.lang,'service')}</NavLink>
                <NavLink to="/project" className="nav-item nav-link">{getTranslation(props.lang,'project')}</NavLink>
                <NavLink to="/recruitment" className="nav-item nav-link" >{getTranslation(props.lang,'recruitment')}</NavLink>
                <NavLink to="/devis" className="nav-item nav-link" >{getTranslation(props.lang,'devis')}</NavLink>
                <NavLink to="/contact" className="nav-item nav-link">Contact</NavLink>
            </Nav>
            <Nav className="d-xl-flex  d-none navbar-nav ms-auto p-0 p-lg-0 ">
               <ConnectionButtonHeader lang={props.lang}/>
            </Nav>
            
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="end"
              show={show}
              backdrop="static"
              >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`} >
                
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body  >
              <h3 className="mb-4 text-center" style={{textShadow: "0px 5px 5px #ffc107",color:"#318af2"}}>Menu</h3>
              <Nav className="justify-content-end flex-grow-1 ">
                <NavLink to="/" className="nav-item nav-link text-center fw-bold text-uppercase" onClick={closeOffCanvas}>{getTranslation(props.lang,'home')}</NavLink>
                <NavLink to="/about" className="nav-item text-nowrap nav-link text-center fw-bold text-uppercase" onClick={closeOffCanvas}>{getTranslation(props.lang,'about')}</NavLink>
                <NavLink to="/service" className="nav-item nav-link text-center fw-bold text-uppercase" onClick={closeOffCanvas}>{getTranslation(props.lang,'service')}</NavLink>
                <NavLink to="/project" className="nav-item nav-link text-center fw-bold text-uppercase" onClick={closeOffCanvas}>{getTranslation(props.lang,'project')}</NavLink>
                <NavLink to="/recruitment" className="nav-item nav-link text-center fw-bold text-uppercase" onClick={closeOffCanvas}>{getTranslation(props.lang,'recruitment')}</NavLink>
                <NavLink to="/devis" className="nav-item nav-link text-center fw-bold text-uppercase" onClick={closeOffCanvas}>{getTranslation(props.lang,'devis')}</NavLink>
                <NavLink to="/contact" className="nav-item nav-link text-center fw-bold text-uppercase" onClick={closeOffCanvas}>Contact</NavLink>
                <ConnectionButtonOffCanvas lang={props.lang} closeOffCanvas = {closeOffCanvas}/>
                </Nav>
              </Offcanvas.Body>
            </Navbar.Offcanvas> 
          </Container>
        </Navbar>
      ))}
      
    </div>


  );
}

