import React  from "react";
import Title from "../about/Title"
import {
  Outlet
} from "react-router-dom";
export default function Services(props) {
  return (
    <>
        <Title/>
        <Outlet/>
    </>
    );
}