import React, {useState,useEffect} from "react";
import {Row,Col,Modal,FloatingLabel,Form,Button}  from 'react-bootstrap';
import { AdminService } from "../../../../../services/admin.service";

const capitalize = (str) => {
    return str[0].toUpperCase() + str.slice(1);
};


export default function  AdminAddUser(){
    const [isLoading, setLoading] = useState(false);
    const [account, setAccount ] = useState({roles:[],providers:{}})
    const handleClick = () => setLoading(true);
    const [modalShow, setModalShow] = useState(false);
    const handleEndInput = (e)=>{
        var roles = account.roles;
        if(e.target.value !== "on"){
            setAccount((prev)=> ({...prev,[e.target.title]: e.target.value}))
        }else if( e.target.checked ){
            roles = [];
            roles.push(e.target.title)
            setAccount((prev)=> ({...prev,roles:roles}))

        }else if ( ! e.target.checked ){
            roles.splice(roles.findIndex((item)=>{ return item === e.target.title}),1)
            setAccount((prev)=> ({...prev,roles:roles}))
        }
    }
    const handleProviders = (e)=>{
        var providers = account.providers;
        if( e.target.checked ){
            providers[e.target.title]={id:"",pwd:""}
        }else if( !e.target.checked){
            delete providers[e.target.title]
        }
        setAccount((prev)=> ({...prev,providers:providers}))
    }
    const handleProvidersInfo = (e)=>{
        var providers = account.providers;
        providers[e.target.title.split('_')[0]][e.target.title.split('_')[1]]= e.target.value
        setAccount((prev)=> ({...prev,providers:providers})) 
    }
    const handleFonctionEmploye = (e)=>{
        setAccount((prev)=> ({...prev,fonction:e.target.value}))
    }
    const handleAgenceEmploye = (e)=> {
        setAccount((prev)=> ({...prev,agence:e.target.value}))
    }
    useEffect(() => {
        const clearData = account;
        if(account.roles.includes("employe"))
            delete clearData["providers"]
        if(account.roles.includes("client")){
            delete clearData["fonction"];
            delete clearData["agence"];
        }


      if (isLoading) {
        AdminService.setNewUser(account).then(res=> {
                if(res?.message === 200){
                    setLoading(false)
                    setModalShow(true)
                }else{
                    alert(res?.message)
                    setLoading(false)
                }
        })
        setLoading(false)
      }
    }, [isLoading,account]);
    return( <>  
    <div className="text-center mx-auto mb-5" style={{maxWidth: '600px'}}>
        <h1 className="mb-4" style={{textShadow: "0px 5px 5px #ffc107",color:"#318af2"}}>Nouveau utilisateur</h1>
    </div>
    <Row className="g-2  justify-content-center  border-bottom text-dark">
        <Col lg={6}>
            <FloatingLabel controlId="username" label="identifiant">
                <Form.Control type="username" placeholder="pseudo"  title="username" defaultValue = {"" || account.username}   onBlur={(e)=>handleEndInput(e)} />
            </FloatingLabel>
        </Col>
        <Col lg={6} >
            <FloatingLabel controlId="password" label="Mot de passe">
                <Form.Control type="password" placeholder="Mot de passe" title="password" defaultValue = {"" || account.password}  onBlur={(e)=>handleEndInput(e)} />
            </FloatingLabel>
        </Col>
        <Col lg={6} >
            <FloatingLabel controlId="nom" label="Nom de famille">
                <Form.Control type="username" placeholder="Nom de famille" title="nom" defaultValue = {"" || account.nom}  onBlur={(e)=>handleEndInput(e)} />
            </FloatingLabel>
        </Col>
        <Col lg={6}>
            <FloatingLabel controlId="prenom" label="Prenom">
                <Form.Control type="username" placeholder="Prenom" title="prenom" defaultValue = {"" || account.prenom}   onBlur={(e)=>handleEndInput(e)}/>
            </FloatingLabel>
        </Col>
        <Col lg={6}>
            <FloatingLabel controlId="email" label="Adresse mail">
                <Form.Control type="email" placeholder="name@example.com" defaultValue = {"" || account.email}  title="email" onBlur={(e)=>handleEndInput(e)} />
            </FloatingLabel>
        </Col>
        <Col lg={6}>
            <FloatingLabel controlId="telephone" label="Téléphone">
                <Form.Control type="phone" placeholder="numéro de téléphone" title="tel" defaultValue = {"" || account.tel}   onBlur={(e)=>handleEndInput(e)}/>
            </FloatingLabel>
        </Col>
        
    
        <Col className="d-flex justify-content-center p-4">
                <Form.Check type="checkbox" className="text-center">
                    <Form.Check.Input type="checkbox" title="client"  checked = {account.roles.includes("client")} onChange={(e)=>handleEndInput(e)} />
                    <Form.Check.Label>Client</Form.Check.Label>
                </Form.Check>
        </Col>
        <Col className="d-flex justify-content-center p-4">
                <Form.Check type="checkbox">
                    <Form.Check.Input type="checkbox"  title="employe" checked = { account.roles.includes("employe")}  onChange={(e)=>handleEndInput(e)}/>
                    <Form.Check.Label>Employé</Form.Check.Label>
                </Form.Check>
        </Col>
        <Col className="d-flex justify-content-center p-4">
                <Form.Check type="checkbox">
                    <Form.Check.Input type="checkbox" title="admin" checked = {account.roles.includes("admin")}  onChange={(e)=>handleEndInput(e)} />
                    <Form.Check.Label>Admin</Form.Check.Label>
                </Form.Check>
        </Col>
    </Row>
    {account && account.roles.includes("client") ?
    <Row className=" g-2 text-dark border-bottom">
        <div className="text-center mx-auto pt-2" >
                <h3 className="mb-1 text-primary" >Providers</h3>
        </div>
        <Col className="d-flex justify-content-center p-4">
                <Form.Check type="checkbox" className="text-center" >
                    <Form.Check.Input type="checkbox" title="senergy" defaultChecked = {account.providers.senergy? true : false}   onClick={(e)=>handleProviders(e)} />
                    <Form.Check.Label>Senergy</Form.Check.Label>
                </Form.Check>
        </Col>
        <Col className="d-flex justify-content-center p-4">
                <Form.Check type="checkbox" >
                    <Form.Check.Input type="checkbox"  title="growatt" defaultChecked = {account.providers.growatt? true : false}  onClick={(e)=>handleProviders(e)} />
                    <Form.Check.Label>Growatt</Form.Check.Label>
                </Form.Check>
        </Col>
        <Col className="d-flex justify-content-center p-4">
                <Form.Check type="checkbox" >
                    <Form.Check.Input type="checkbox" title="autres" onClick={(e)=>handleProviders(e)} disabled/>
                    <Form.Check.Label>others</Form.Check.Label>
                </Form.Check>
        </Col>
    </Row>
    : ' '}

    {account && account.roles.includes("employe") ?
        <Row className=" g-2 text-dark border-bottom">
            <div className="text-center mx-auto pt-2" >
                    <h3 className="mb-1 text-primary" >Fonction à Universal Energy</h3>
            </div>
            <Col className="d-flex justify-content-center p-4">
                <Form.Select  className="text-center text-dark" defaultValue = {"" || account.fonction} aria-label="Fonction" onChange={(e=>{handleFonctionEmploye(e) })} >
                    <option value="">Poste au sein de l'quipe</option>
                    <option value="commercial">Commercial</option>
                    <option value="ingenieur">Ingénieur</option>
                    <option value="technicien">Technicien</option>
                    <option value="comptable">Comptable</option>
                    <option value="directeur">Directeur</option>
                    <option value="chef">Chef d'équipe</option>
                </Form.Select>
            </Col>
            <Col className="d-flex justify-content-center p-4">
                <Form.Select  className="text-center text-dark" defaultValue = {"" || account.agence} aria-label="Agence" onChange={(e=>{handleAgenceEmploye(e) })} >
                    <option value="">Agence</option>
                    <option value="midoun">Midoun</option>
                    <option value="houmtSouk">Houmt souk</option>
                    <option value="guellala">Guellala</option>
                    <option value="tataouine">Tatouine</option>
                    <option value="nabeul">Nabeul</option>
                    <option value="tunis">Tunis</option>
                </Form.Select>
            </Col>

        </Row>
    : ' '}

    {account && Object.keys(account.providers).length !== 0 ?
    Object.keys(account.providers).map((item,index)=>{
        return (
        <Row className="g-2 text-dark border-bottom" key={index}>
            <div className="text-center mx-auto pt-2" >
                <h3 className="mb-1 text-primary" >{capitalize(item)}</h3>
            </div>
            <Col lg={6} className="d-flex justify-content-center p-4" >
                <FloatingLabel  label="Identifiant">
                    <Form.Control type="text" placeholder="pseudo" defaultValue = {"" || account.providers[item][item+"_id"]} title={item+"_id"} onBlur={(e)=>handleProvidersInfo(e)} />
                </FloatingLabel>
            </Col>
            <Col lg={6} className="d-flex justify-content-center p-4">
                <FloatingLabel  label="Mot de passe">
                    <Form.Control type="password" placeholder="password" defaultValue = {"" || account.providers[item][item+"_pwd"]}  title={item +"_pwd"} onBlur={(e)=>handleProvidersInfo(e)} />
                </FloatingLabel>
            </Col>
        </Row>) 
    })
    : ' '}
    
    <Button
      variant="primary"
      disabled={isLoading}
      onClick={!isLoading ? handleClick : null}
      className="m-3"
    >
      {isLoading ? 'en cours...' : 'Enregistrer'}
    </Button>
    <CenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        user={account}
      />
    </> ) ;

    
}

function CenteredModal(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter" className="text-success">
            Registration Terminée!
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4>Nouveau utilisateur : {props.user.username}</h4>
          <span className="text-dark">mail : {props.user.email}</span><br></br>
          <span className="text-dark">tel : {props.user.tel}</span><br></br>
          <span className="text-dark">Roles : {props.user.roles}</span><br></br>
          <p className="text-danger fw-bolder">
            <strong>Nb:</strong>En cas d'erreur merci de supprimer l'utilisateur.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button className="text-dark btn-danger" onClick={props.onHide}>Supprimer</Button>
        </Modal.Footer>
      </Modal>
    );
}