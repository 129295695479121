import React from "react";
import Dropdown from 'react-bootstrap/Dropdown';
import useAuth from "../../../services/authentication/useAuth";
import getTranslation from "../../header/HeaderLang";
import {
    useNavigate,
    Link
  } from "react-router-dom";


    
export default function ConnectionButtonHeader(props){
    const auth = useAuth()
    return (
        <>
        {(auth.user ) ? 
        <Dropdown align="end">
            <Dropdown.Toggle data-toggle="dropdown" className=" nav-item nav-link text-nowrap text-center  shadow-lg p-1 bg-warning  rounded">
            <i className="bi bi-person-circle"></i> {auth.user}
            </Dropdown.Toggle>

            <Dropdown.Menu>
            <DropdownMenu/>
            </Dropdown.Menu>
        </Dropdown> :
        <Link  to="/user"  className=" nav-item nav-link text-nowrap text-center  shadow-lg p-1 bg-warning  rounded">
        {getTranslation(props.lang,'signin')}<i className="fa fa-arrow-right ms-3 mt-1"></i>
      </Link>
      
        }
    </>
    );
}


export const ConnectionButtonOffCanvas = (props) => {
    const auth = useAuth()
    return (
        <>
        {(auth.user) ? 
        <Dropdown className="text-center" >
            <Dropdown.Toggle data-toggle="dropdown" title="userDropdown" className=" d-felx text-nowrap text-center  d-auto shadow-lg p-2 bg-warning  rounded">
            <i className="bi bi-person-circle" title="userDropdown" ></i> {auth.user}
            </Dropdown.Toggle>

            <Dropdown.Menu className="text-center">
                <DropdownMenu/>
            </Dropdown.Menu>
        </Dropdown> :
        <Link  to="/user"  className="nav-item nav-link text-nowrap text-center d-block m-5 shadow-lg p-2  bg-warning  rounded" onClick={props.closeOffCanvas}>
        {getTranslation(props.lang,'signin')}<i className="fa fa-arrow-right ms-3"></i></Link>
      
        }
    </>
    );
}


function DropdownMenu(){
    let navigate = useNavigate();
    const auth = useAuth()
    if(auth.role.includes('ROLE_ADMIN'))
        return (
            <>
                    <Dropdown.Item as="button" onClick={()=>navigate("/user")}>Dashboard</Dropdown.Item>
                    <Dropdown.Item as="button" onClick={()=>navigate("/user/profil")}>Profil</Dropdown.Item>
                    <Dropdown.Item as="button" onClick={()=>{navigate("/");auth.signout()}}>Log out</Dropdown.Item>
            </>
        );
    else
        return (
            <>
                    <Dropdown.Item as="button" onClick={()=>navigate("/user")}>Dashboard</Dropdown.Item>
                    <Dropdown.Item as="button" onClick={()=>navigate("/user/profil")}>Profil</Dropdown.Item>
                    <Dropdown.Item as="button" onClick={()=>navigate("/user/documents")}>Documents</Dropdown.Item>
                    <Dropdown.Item as="button" onClick={()=>{navigate("/");auth.signout()}}>Log out</Dropdown.Item>
            </>
        );
            
}