import React from "react";
import ContactFormulaire from "./ContactFormulaire";
import ContactMap from "./ContactMap";

export default function ContactStruct(props) {


    return (
        <div className="container-fluid bg-light overflow-hidden px-lg-0" style={{margin: "6rem 0"}}>
            <div className="container contact px-lg-0">
                <div className="row g-0 mx-lg-0">
                    <div className="col-lg-6 text-center py-5">
                        <ContactFormulaire/>
                    </div>
                    <div className="col-lg-6 pe-lg-0" style={{minHeight: '400px'}}>
                        <ContactMap/>
                    </div>
                    
                </div>
            </div>
        </div>
    );

}

