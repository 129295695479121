import React from "react";


import {
    Link
  } from "react-router-dom";


export default function PanneauxSolaires(props) {

  return (
    <>
        <div className="container-xxl py-5">
            <div className="container">
                <div className="text-center mx-auto mb-5" style={{maxWidth: "600px"}}>
                    <h1 className="mb-4" style={{textShadow: "0px 5px 5px #ffc107",color:"#318af2"}}>Choisissez selon votre status</h1>
                </div>
                <div className="row g-4">
                    <div className="col-md-6 col-lg-4 " >
                        <div className="service-item rounded overflow-hidden" >
                            
                            <div className="position-relative text-center  p-4 pt-5">
                                <div className=" mt-3 ">
                                    <i className="bi bi-house-door text-primary fa-3x"></i>
                                </div>
                                <h4 className="mb-3">Particulier</h4>
                                <p>Réduisez vos factures de Steg en installant du photovoltaique. Découvrez toutes les démarches et les aides...</p>
                                <Link to="particular" className="small fw-medium" >Voir plus<i className="fa fa-arrow-right ms-2"></i></Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-4 ">
                        <div className="service-item rounded overflow-hidden">
                            
                            <div className="position-relative text-center p-4 pt-5">
                                <div className=" mt-3">
                                    <i className="bi bi-building text-primary fa-3x"></i>
                                </div>
                                <h4 className="mb-3">Professionnel</h4>
                                <p>Vous cherchez à réduires vos charges d'éléctricité, et générer plus de revenus.</p>
                                <Link to="Professional" className="small fw-medium" href="">Voir plus<i className="fa fa-arrow-right ms-2"></i></Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-4 ">
                        <div className="service-item rounded overflow-hidden">
                           
                            <div className="position-relative text-center p-4 pt-5">
                                <div className=" mt-3">
                                    <i className="bi bi-tree text-primary fa-3x"></i>
                                </div>
                                <h4 className="mb-3">Agriculteur</h4>
                                <p>Vous avez aucun raccordement de Steg, vous pouvez générer d'éléctricité pour pomper de l'eau et allumer les divers machines.</p>
                                <Link to="farmer" className="small fw-medium" href="">Voir plus<i className="fa fa-arrow-right ms-2"></i></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
    );
}