import React from "react";
import CarsouselPub from "./CarouselPub";
import Projects from "./Projects";
import Stat from "../about/Stat";

export default function Project(props) {
  return (
    <>
      <CarsouselPub/>
      <Stat/>
      <Projects/>
    </> 
    );
}
