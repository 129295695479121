import axios from 'axios';
const API_URL = process.env.REACT_APP_API ||'http://localhost:3001';
const API_PUBLIC = "/api/public/"
const PublicService = {
  setNewDevis(options) {
    return axios.post(API_URL +API_PUBLIC+ 'newDevis',options)
      .then(res => {
        return res.data;
      }).catch((err)=>{
        return err.response.data;
      })
  },
  setNewContact(options) {
    return axios.post(API_URL + API_PUBLIC+'newContact',options)
      .then(res => {
        return res.data;
      }).catch((err)=>{
        return err.response.data;
      })
  },
  getCaptcha(){
    return axios.get(API_URL + API_PUBLIC+'captcha')
    .then(res => {
      return res.data;
    }).catch((err)=>{
      return err.response.data;
    })
  },
  setNewCandidature(options){
    return axios.post(API_URL + API_PUBLIC+'newCandidature',options)
      .then(res => {
        return res.data;
      }).catch((err)=>{
        return err.response.data;
      })
  }
}
export {PublicService};
