import axios from 'axios';
import authHeader from './authentication/auth-header';
const API_URL = process.env.REACT_APP_API ||'http://localhost:3000';
const API_USR = "/api/admin/";
const AdminService = {
  setNewUser(options) {
    return axios.post(API_URL + API_USR +'addUser',options, { headers: authHeader()})
      .then(res => {
        return res.data;
      }).catch((err)=>{
        return err.response.data;
      })
  },
  getUsersList() {
    return axios.get(API_URL + API_USR+ 'usersList',{ headers: authHeader()})
      .then(res => {
        return res.data.message;
      }).catch((err)=>{
        return [];
      })
  },
  updateProvider(options){
    return axios.post(API_URL +API_USR+ 'usersProviderAuth',options, { headers: authHeader()})
      .then(res => {
        return res.data;
      }).catch((err)=>{
        return err.response.data;
      })
  },
  getDevisList(){
    return axios.get(API_URL + API_USR+'devisList', { headers: authHeader()})
      .then(res => {
        return res.data;
      }).catch((err)=>{
        return err.response.data;
      })
  },
  openDevisListDetails(options){
    return axios.put(API_URL + API_USR+'devisJustSeen',options, { headers: authHeader()})
    .then(res => {
      return res.data;
    }).catch((err)=>{
      return err.response.data;
    })
  },
  devisDelete(options){
    return axios.delete(API_URL + API_USR+'devisDelete/'+options, { headers: authHeader()})
    .then(res => {
      return res.data;
    }).catch((err)=>{
      return err.response.data;
    })
  },
  getContactList(){
    return axios.get(API_URL + API_USR+'contactList', { headers: authHeader()})
      .then(res => {
        return res.data;
      }).catch((err)=>{
        return err.response.data;
      })
  },
  openContactListDetails(options){
    return axios.put(API_URL + API_USR+'contactJustSeen',options, { headers: authHeader()})
    .then(res => {
      return res.data;
    }).catch((err)=>{
      return err.response.data;
    })
  },
  contactDelete(options){
    return axios.delete(API_URL + API_USR+'contactDelete/'+options, { headers: authHeader()})
    .then(res => {
      return res.data;
    }).catch((err)=>{
      return err.response.data;
    })
  }
}
export {AdminService};

