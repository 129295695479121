import React from "react";



export default function ContactMap(props) {
    return ( 
        <div className="position-relative h-100">
            <iframe className="position-absolute w-100 h-100" style={{objectFit: "cover"}} title="location universal energy"
            src="https://maps.google.com/maps?q=universal%20energy%20djerba&amp;t=&amp;z=13&amp;ie=UTF8&amp;iwloc=&amp;output=embed"
            frameBorder="0" aria-hidden="false"
            tabIndex="0" >
            </iframe> 
        </div>

    );

}
