
import React, {useState,useRef,useEffect} from "react";
import {Row,Col,Container,Modal,Button,Card}  from 'react-bootstrap';
import { AdminService } from "../../../../../services/admin.service";
import SummaryForm from "../../../../devis/SummaryForm";
import useAuth from "../../../../../services/authentication/useAuth";

const capitalize = (str) => {
    return str[0].toUpperCase() + str.slice(1);
};

export default function AdminDevis ( props){
    const mountedRef = useRef(true)
    const [search,setSearch] = useState("")
    const [devisList,setDevisList] = useState([])
    const [show,setShow] = useState(true);
    const [devisDetails,setDevisDetails] = useState({})
    const [modalShow, setModalShow] = useState(false);
    const auth = useAuth();
    const handleSearch = (e)=>{
        setSearch(e.target.value.toLowerCase())
        if(show === false)
            setShow(!show)
    }
    const handleDevisDetails = (id) => {
        
        const item = devisList.find((item)=> item._id === id)
        setDevisDetails(item)
        if(!item.state.seen.includes(auth.user)){
          
            AdminService.openDevisListDetails({id:id,username:auth.user}).then(res=>{
                if(res.success && res.success === 200){
                    setDevisList(devisList.map(oldItem =>{
                        if(item._id === oldItem._id)
                            oldItem.state.seen.push(auth.user)
                        return oldItem
                    }))
                }
            })
        }
        setShow(false)
    }

    const handleDevisDelete = () =>{
        setDevisList(devisList.filter(oldItem =>{
            if(oldItem._id !== devisDetails._id)
                return oldItem;
            return false;
        }))
        setShow(true)
    }
    
    useEffect(()=>{
        AdminService.getDevisList().then((res)=>{
            if(res.length !== 0 ){
                if (!mountedRef.current) 
                    return null
                setDevisList(res.message)
            }
        })
        return () => { 
            mountedRef.current = false
        }
    },[])
    return (
        <Container>
            <Row className="justify-content-center text-center">
                <Col  xs={'auto'}>
                    <input className="form-control" placeholder="Chercher un devis" onChange={handleSearch} title="search" style={{width:"200px"}} ></input>
                </Col>        
            </Row>

            <Row className="justify-content-center text-center">
                {show ? 
                            <DevisListCard
                            search={search } 
                            devisList={devisList}
                            handleDevisDetails={handleDevisDetails}
                            /> 
                : 
                <>
                    <div className="d-flex flex-row-reverse">
                        <div className="p-2"><i className="bi bi-x text-dark " onClick={()=>setShow(true)} style={{fontSize:"38px"}}></i></div>
                        <div className="p-2"><i className="bi bi-trash-fill text-danger" onClick={()=>{setModalShow(true)}} style={{fontSize:"33px"}} ></i></div>
                    </div>
            
                    <SummaryForm client={devisDetails}/> 
                </>
                
                }
                
            </Row> 
            <DeleteDevis
                show={modalShow}
                onHide={() => setModalShow(false)}
                item={devisDetails}
                delete={handleDevisDelete}
            />
        </Container>
    );
}

function DevisListCard ( props){
    const auth = useAuth();

    return (props.devisList.filter((item,index)=>{
        if(props.search === ""){
            return item
        }else{
            if(item.number.toLowerCase().search(props.search) !== -1 
                || item.projects[0].projectType.toLowerCase().search(props.search) !== -1
                || item.projects[0].city.toLowerCase().search(props.search) !== -1){
                    
                return item
            }
        }
        return false;
    }).map((item,index)=>{

        
        return (
            <Col xs="auto" key={index}>

                    <Card  className="m-2" bg={item.state.seen.includes(auth.user) === true ?  "light" : "warning"  } onClick={()=>props.handleDevisDetails(item._id)}>
                        <Card.Body>
                        <Card.Title className="text-capitalize fw-normal">{"Devis N°" +item.number}</Card.Title>
                        
                        <span className="text-dark">
                            {item.projects[0].projectType} 
                        </span>
                        <br></br>
                        <span className="text-dark">
                            {capitalize(item.projects[0].city)+", "+capitalize(item.projects[0].country) }
                        </span>
                        </Card.Body>
                        <Card.Footer>
                        <small>
                            { item.createdIn? "Fait le " : ''}
                        <span className="text-primary">{ item.createdIn? item.createdIn.split(',')[0] : ''}</span>
                        { item.createdIn? " à " : ''}
                        <span className="text-primary"> { item.createdIn? item.createdIn.split(',')[1].split('.')[0] : ''}</span></small>
                        <hr></hr>
                        <small>
                            <span className={item.state.progress.length === 0?"text-danger" : "text-primary" }>{item.state.progress.length === 0? "Non traité" : item.state.progress[item.state.progress.length-1]} </span>
                        </small>
                        <hr></hr>
                        <small>
                            <span className="text-primary"> {item.state.seen.length} vu(s)</span>
                        </small>
                        </Card.Footer>
                        
                            
                    
                        
                    </Card>
               
            </Col>
        )
    }))
}


function DeleteDevis(props) {

    const modalAttributes = {...props};
    delete modalAttributes['delete']

    const deleteDevis = ()=>{
        AdminService.devisDelete(props.item._id).then(res=>{
            if(res.success && res.success === 200){
                props.onHide()
                props.delete()
            }
        })
    }
    
    return (
      <Modal
        {...modalAttributes}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter" className="text-primary text-center">
          Devis N°{props.item.number} 
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-dark">
        Confirmez la suppression de ce devis.
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={deleteDevis} className='bg-danger'>Supprimer</Button>
        </Modal.Footer>
      </Modal>
    );
}