import React from "react";


export default function PompageForm(props) {
    
    const handleChange = (e)=>{
        props.handleConsommation(new Array({powerHorse:e.target.value,powerKhw:(parseInt(e.target.value)* 0.74569987).toFixed(2) + ' Kwh',powerPv:(parseInt(e.target.value)* 0.74569987 *1.5).toFixed(2) + ' Kwc' }))
    }
  return (
      <>
    <h4 className='mb-3 text-center'>Consommation</h4>
    <div className='col-md-6'> 
        <label htmlFor='puissance_pompage' className='form-label'>Puissance de pompage</label>
        <input className='form-control text-dark'  placeholder='Nombre de cheveaux' value ={props.consommation.length === 0 || props.consommation[0].powerHorse === undefined ? 0 : props.consommation[0].powerHorse} onChange={e => {handleChange(e)}} required></input>
        <div className='invalid-feedback'>Puissance non valide. </div>
    </div>
    <div className='col-3'>
        <div className='row'><label htmlFor='total_puissance' className='col-sm-12 dark col-form-label '>Puissance</label>
            <div className='col-sm-12'>
                <input type='text' value ={props.consommation.length === 0 || props.consommation[0].powerKhw === undefined ? 0 : props.consommation[0].powerKhw}  className='form-control-plaintext text-dark'  readOnly></input>
                </div>
        </div>
    </div>
    <div className='col-3'>
        <div className='row'><label htmlFor='total_puissance_pv' className='col-sm-12 dark col-form-label '>PV</label>
            <div className='col-sm-12'>
                <input type='text' value ={props.consommation.length === 0 || props.consommation[0].powerPv === undefined ? 0 : props.consommation[0].powerPv}  className='form-control-plaintext text-dark' readOnly></input>
                </div>
        </div>
    </div>
    </>
    );
}
