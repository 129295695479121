import React from "react";
import AboutPicture from "../../assets/img/about1.jpg"

export default function Presentation(props) {
  return (
    <div className="container-fluid bg-light overflow-hidden my-5 px-lg-0" id="about_component">
        <div className="container about px-lg-0">
            <div className="row g-0 mx-lg-0">
                <div className="col-lg-6 ps-lg-0 " style={{minHeight: '400px'}}>
                    <div className="position-relative h-100">
                        <img className="position-absolute img-fluid w-100 h-100" src={AboutPicture} style={{objectFit: 'cover',objectPosition: 'bottom center'}} alt=""></img>
                    </div>
                </div>
                <div className="col-lg-6 about-text p-5 py-5">
                    <div className="p-lg-5  pe-lg-0  text-dark">
                        <h1 className="mb-4 text-center text-warning">6+ Années D'Experience En Solaire & En Energie renouvelable</h1>
                        <p style={{textAlign: "justify",textJustify: "inter-word"}}>Universal Energy est présente sur le marché des énergie renouvelables depuis 2017, sise à Djerba et ayant d'autres bureau d'étude sises à Tataouine et Nabeul. la société est spécialisée dans le développement des projets dans le domaine des énergies renouvelables avec un focus sur l’énergie solaire. Son centre de compétence est bâti autour d’un pool d’ingénieurs qui sont spécialisés en conception de projet énergétique pour les secteurs privée et public.</p>
                        <p><i className="fa fa-check-circle text-primary me-3"></i>Nous sommes essaimée par la STEG</p>
                        <p><i className="fa fa-check-circle text-primary me-3"></i>Agréé par l’Agence Nationale de Maitrise de l’Energie (ANME)</p>
                        <p>Portée par son succès et une croissance remarquable, notre société est composée à 80% de cadres supérieurs. Dans une perspective d'expansion continue, nous avons l'intention d'embaucher 10 nouveaux collaborateurs au cours des deux prochaines années.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    );
}