import React , { useState } from "react";


export default function SiForm(props) {
    const [item,setItem] = useState('');

    const handleChange = (e) => {
        setItem(previousState => {
            return { ...previousState, [e.target.title]:e.target.value }
          })
    }
    const addItem = () => {
        if(Array.isArray(props.consommation))
            props.handleConsommation([...props.consommation,item])
        else
        props.handleConsommation([item])
        setItem('');
    }
    const ItemsList = () => {
        if(props.consommation !== undefined && Array.isArray(props.consommation))
            return (
                props.consommation.map((item,index)=>{
                    return (
                        <tr key={index}>
                            <th scope='row' >{index}</th>
                            <td>{item.deviceName}</td>
                            <td>{item.count}</td>
                            <td>{item.timeUse}</td>
                            <td>{item.power}</td>
                            <td><button className="btn btn-danger"  onClick={(e)=> deleteItem(index,e)}>-</button></td>   
                        </tr>
                    );
                })
            );
        return null;
    }
    
    const deleteItem = (itemIndex,e) => {
        e.preventDefault()
        props.handleConsommation(props.consommation.filter((item,index) => {return  index !== itemIndex}))
        setItem('');
    }

  return (
      <>
        <h4 className='mb-3 text-center'>Consommation</h4>
        <div className='col-md-2'>
            <label htmlFor='nombre_equipement' className='form-label'>Nb equipement </label>
            <input type='number' value={item.count === undefined ? "" : item.count}  onChange={(e)=>{handleChange(e)}}  title="count" className='form-control text-dark' placeholder=''></input>
        </div>
        <div className='col-md-4'> 
            <label htmlFor='equipement' className='form-label'>Equipement</label>
            <input type='text' value={item.deviceName === undefined ? "" : item.deviceName }onChange={(e)=>{handleChange(e)}} title="deviceName"   className='form-control text-dark'  placeholder=''></input>
        </div>
        <div className='col-md-2'> 
            <label htmlFor='heures_equipement' className='form-label'>durée utilisation</label> 
            <input type='text' value={item.timeUse === undefined ? "" : item.timeUse } onChange={(e)=>{handleChange(e)}}  title="timeUse"  className='form-control text-dark'  placeholder=''></input>
        </div>
        <div className='col-md-2'> 
            <label htmlFor='puissance_equipement' className='form-label'>Puissance (kwh)</label> 
            <input type='text' value={item.power === undefined ? "" : item.power} onChange={(e)=>{handleChange(e)}}  title="power"  className='form-control text-dark'  placeholder=''></input> 
        </div>
        <div className='col-md-2 '>
            <div className='row justify-content-md-center'>
                <div className='col-12' align='center'><label htmlFor='nombre_equipement' className='form-label '>Ajouter</label></div>
                <div className='col-12' align='center'><button className='btn btn-primary btn-lg' type='button' onClick={addItem} ><i className='fa fa-plus'></i></button></div>
            </div>
        </div>
        
        <div className='table-responsive'>
            <table className='table'>
                <thead>
                    <tr>
                        <th scope='col'>#</th>
                        <th scope='col'>Equipement</th>
                        <th scope='col'>Nombre d'equipement</th>
                        <th scope='col'>Heures d'utilisation</th>
                        <th scope='col'>Puissance</th>
                        <th scope='col'>Edit</th>
                    </tr>
                </thead>
                <tbody id='added'>
                    {ItemsList()}
                   { <Total consommation={props.consommation}/>}
                </tbody>
            </table>
        </div>
    </>
    );
}



function Total(props){
    return (
        <tr className='table-info'>
            <th scope='row' >Total</th>
            <td></td>
            <td >{props.consommation.length > 0 ? props.consommation.reduce((total,item)=>{return total + parseInt(item.count)},0) : 0}</td>
            <td></td>
            <td >{props.consommation.length > 0 ? props.consommation.reduce(( total,item)=> {return total +(parseInt(item.power) * parseInt(item.count) * parseInt(item.timeUse))} , 0) : 0}</td>
            <td></td>
        </tr>
    );
}
