import axios from 'axios';
import authHeader from './authentication/auth-header';
const API_URL = process.env.REACT_APP_API ||'http://localhost:3000';
const API_USR = "/api/user/";
const UserService = {
  getUserPlantList(callback) {
    let response = undefined;
    const {lastReFetch} = reFetch("plantListRefetch")
    response = JSON.parse(localStorage.getItem('plantList'))

    if( lastReFetch || response === null || Object.keys(response).length === 0 || response === undefined || response.status === false  ){
      return axios.get(API_URL +API_USR+ 'plantList', { headers: authHeader()})
      .then(res => {
          return JSON.parse(res.data.monotoring) ;
      }).then(res => {
        localStorage.setItem("plantList", JSON.stringify(res));
        callback(res)
        return res
      });
    }
    callback(response)
    return response;
      
  },
  getUserTotal(callback) {
    let response = undefined;
    const {lastReFetch} = reFetch("GlobalTotal")
    response = JSON.parse(localStorage.getItem('total'))
    if( lastReFetch || response === null || Object.keys(response).length === 0 || response === undefined || response.status === false  ){
      return axios.get(API_URL + API_USR+'total', { headers: authHeader()})
      .then(res => {
          return JSON.parse(res.data.synergy);
      }).then(res => {
        localStorage.setItem("total", JSON.stringify(res));
        callback(res)
        return res
      });
    }
    callback(response)
    return response;
      
  },
  getPlantDetail(provider,GroupAutoId,date,mode,today,callback) {
    let response = undefined;
    const {lastReFetch} = reFetch("Item")
    response = JSON.parse(localStorage.getItem(GroupAutoId))
 
    
    if( !today || lastReFetch || response === null || response === undefined|| Object.keys(response).length === 0  || response.status === false){
      return axios.post(API_URL +API_USR+ 'plantDayDetails',{provider:provider,GroupAutoID:GroupAutoId,date:date,mode:mode,today:today}, { headers: authHeader()})
      .then(res => {
        return JSON.parse(res.data.detail);
      }).then(res => {
        if(today)
          localStorage.setItem(GroupAutoId, JSON.stringify(res));
        callback(res)
        return res
      });
    }
    callback(response)
    return response;
      
  }
}
export {UserService};

function reFetch(GroupAutoId){
  let lastReFetch = localStorage.getItem("last"+GroupAutoId+"ReFetch")
  let now = new Date(new Date().toLocaleString('en-US', { timeZone: 'Africa/Tunis'  }));
  if(lastReFetch){
    lastReFetch= new Date (lastReFetch);
    if(lastReFetch -now <= 0){
      lastReFetch = true;
      if(now.getMinutes() < 20) 
        now.setMinutes(20) 
      else if(now.getMinutes() < 40 )
         now.setMinutes(40)
      else
         now.setMinutes(60)
    }else
      lastReFetch = false;
  }else{
    lastReFetch = true;
    if(now.getMinutes() < 20) 
      now.setMinutes(20) 
    else if(now.getMinutes() < 40 )
     now.setMinutes(40) 
    else
     now.setMinutes(60)
  }
  if(lastReFetch){
    localStorage.setItem("last"+GroupAutoId+"ReFetch", now);
  }
  return {now,lastReFetch};
}

