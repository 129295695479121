import React  from "react";


export default function DownloadFiles(props) {

  return (
    <div className="container-xxl py-5">
        <div className="container">
            <div className="text-center mx-auto mb-5" style={{maxWidth: "600px"}}>
                <h1 className="mb-4 pt-5" style={{textShadow: "0px 5px 5px #ffc107",color:"black"}}>Les documents photovoltaiques fournis par le steg</h1>
            </div>
            <div className="row g-4 pt-5">
                <div className="col-md-12 col-lg-12 " >
                    <h4 ><a target="_blank" className="text-primary" rel="noopener noreferrer" href="http://www.steg.com.tn/$J@5YgwRAxGwKxAzozh-Oy2I8d3zMjvNqbbmaxU9IQsel2yoZL6VYriRnY?tknfv=ce105e2c0-d7bb-48ab-abb5-83a3f4ebp99pvv5">
                    <i className="bi bi-file-earmark-arrow-down"></i> Contrat d’achat par la steg de l’excedent de l'energie éléctrique produite à partir 
                        d'energies renouvelables et livrée sur le réseau basse tension (Programme PROSOL ELEC III)</a></h4>
                    <ul className="text-dark pt-2">
                        <li><a style={{display: "flow-root list-item"}} target="_blank" className="nav-item nav-link" rel="noopener noreferrer" href="http://www.steg.com.tn/$J@5YiQAEhGgRgA4ABTMtuwgwmvedb5ORbkKhor-zX-lHIkAdT7BqnSMyTW5d-1slZ5eAFAVsmDKLpgimW3yCacbFFQra5AK9wN9g?tknfv=ce105e2c0-d7bb-48ab-abb5-83a3f4ebp99pvv5">
                        Avenant au contrat d’achat par la STEG de l’excédent de l’energie électrique produite a partir de l’énergie 
                        solaire photovoltaïque par le producteur résidentiel en basse tension (Bénéficiaire du programme PROSOL ELEC) <i className="bi bi-file-earmark-arrow-down"></i></a>
                        </li>
                    </ul>
                    <h4 className="pt-4"><a target="_blank" className="text-primary" rel="noopener noreferrer" href="http://www.steg.com.tn/$J@5YgQRAxGwQRAzozh-Oy2I8d3zMjvNqbbmVGWLFg6XJMPubOt2TN34gtUULKgB7M--KwY-AImNXWh?tknfv=ce105e2c0-d7bb-48ab-abb5-83a3f4ebp99pvv5">
                    <i className="bi bi-file-earmark-arrow-down"></i> Contrat d’achat par la steg de l’excedent de l’energie electrique produite a partir 
                        d’energies renouvelables et livree sur le reseau basse tension (hors programme prosol)</a></h4>
                    <ul className="text-dark pt-2">
                        <li><a style={{display: "flow-root list-item"}} target="_blank" className="nav-item nav-link" rel="noopener noreferrer" href="https://www.steg.com.tn//$J@5YiQAEhGgRgA4ABTMtuwgwmvedb5ORbkKiIOEVbSPPuCa6A8GPhrxI6pE7ZAAJKeAAC9jC8rsVJFtZyTgKp_xKFOHUZYtFGOkg?tknfv=ce105e2c0-d7bb-48ab-abb5-83a3f4ebp99pvv5">
                        Avenant au contrat d’achat par la STEG de l’excédent de l’energie électrique produite a partir de l’énergie 
                        solaire photovoltaïque par le producteur résidentiel en basse tension (Hors Programme PROSOL) <i className="bi bi-file-earmark-arrow-down"></i></a>
                        </li>
                    </ul>
                    <h4 className="pt-4 text-primary">
                        Demande de l’historique de consommation</h4>
                    <ul className="text-dark pt-2 ">
                        <li><a style={{display: "flow-root list-item"}} target="_blank" className="nav-item nav-link" rel="noopener noreferrer" href="https://www.steg.com.tn/$J@5cxQBEhCwUBAs7Rc0rePr0tthjKAbGudiX_IJNJiqpOsTxkly6jDPu7qs10q37sOl2F_pjb92hE0?tknfv=ce105e2c0-d7bb-48ab-abb5-83a3f4ebp99pvv5">
                            Version arabe <i className="bi bi-file-earmark-arrow-down"></i></a>
                        </li>
                        <li><a style={{display: "flow-root list-item"}} target="_blank" className="nav-item nav-link" rel="noopener noreferrer" href="https://www.steg.com.tn/$J@5cxQBEhCwUBAs7Rc0rePr0tthjKAbGudiUkgvyMVEVcngB0ifyaWTGR7wldUsztmY5qsprEImKVK?tknfv=ce105e2c0-d7bb-48ab-abb5-83a3f4ebp99pvv5">
                            Version française <i className="bi bi-file-earmark-arrow-down"></i></a>
                        </li>
                    </ul>
                    <h4 className="pt-4 text-primary">
                        Demande d’Adhésion au Programme PROSOL-ELEC et de souscription à un crédit bancaire</h4>
                    <ul className="pt-2  ">
                        {/*<li className="d-flex align-items-center d-list-items " >
                            <a style={{display: "flow-root list-item"}} className="nav-item nav-link me-auto"  target="_blank" rel="noopener noreferrer" href="https://www.steg.com.tn/$J@5cgQRAgGxQAEVkahf3Ztj4L5VWPcsWtgwWlYTAWyudzg93L92uOqHHwOsfuUvByvxTFZq2bAdfA3?tknfv=678B2868STUVWXYZBCDEFGHIJKLM4171">
                                Puissance strictement inférieure à 1.5 <i className="bi bi-file-earmark-arrow-down"></i></a>
                            <span className="badge bg-primary rounded-pill">3500 DT</span>
                        </li>
                        <li className="d-flex  align-items-center">
                            <a style={{display: "flow-root list-item"}} className="nav-item nav-link me-auto" target="_blank" rel="noopener noreferrer" href="https://www.steg.com.tn/$J@5cgQRAgGxQAEVkahf3Ztj4L5VWPcsWtgwWlYTAWyudzg93L92uOqHHwaAdYS96kgacwsrDIYYSUd?tknfv=678B2868STUVWXYZBCDEFGHIJKLM4171">
                                Puissance strictement inférieure à 1.5 <i className="bi bi-file-earmark-arrow-down"></i></a>
                            <span className="badge bg-primary rounded-pill">5000 DT</span>
                        </li>
                        <li className=" d-flex  align-items-center">
                            <a style={{display: "flow-root list-item"}} className="nav-item nav-link me-auto"  target="_blank" rel="noopener noreferrer" href="https://www.steg.com.tn/$J@5cgQRAgGxQAEVkahf3Ztj4L5VWPcsWtgwWlYTAWyudzg93L92uOqHHyjDKFjcC0E1gWTIt01OhR0?tknfv=678B2868STUVWXYZBCDEFGHIJKLM4171">
                                Puissance supérieure à 1.5 et strictement inferieure à 2 <i className="bi bi-file-earmark-arrow-down"></i></a>
                            <span className="badge bg-primary rounded-pill">6500 DT</span>
                        </li>*/}
                        <li className=" d-flex align-items-center">
                            <a style={{display: "flow-root list-item"}} className="nav-item nav-link me-auto"  target="_blank"  rel="noopener noreferrer" href="https://www.steg.com.tn/$J@5cgQRAgGxQAEVkahf3Ztj4L5VWPcsWtgwWlYTAWyudzg93L92uOqHHzEGjtnqdJrRnBOa_sDuA0B?tknfv=ce105e2c0-d7bb-48ab-abb5-83a3f4ebp99pvv5">
                                Puissance supérieure à 2 et strictement inferieure à 3 <i className="bi bi-file-earmark-arrow-down"></i></a>
                            <span className="badge bg-primary rounded-pill">7500 DT</span>
                        </li>
                        <li className="d-flex align-items-center">
                            <a style={{display: "flow-root list-item"}} className="nav-item nav-link me-auto"  target="_blank" rel="noopener noreferrer" href="https://www.steg.com.tn/$J@5ciQRAgGxRgEVkahf3Ztj4L5VWPcsWtgwWlYTAWyudzg93L92uOqHHz60YoZ5I0Z-QbGmY60OPNtLDgxiNYa6H3XwldH5MzuBA?tknfv=ce105e2c0-d7bb-48ab-abb5-83a3f4ebp99pvv5">
                                Puissance superieure à 3 <i className="bi bi-file-earmark-arrow-down"></i></a>
                            <span className="badge bg-primary rounded-pill">10000 DT</span>
                        </li>
                    </ul>

                    <div style={{height:"50px"}}></div>
                    
                    <h4 ><a target="_blank" className="text-primary" rel="noopener noreferrer" href="http://www.steg.com.tn/$J@5YxUREgGgkBAZ7HoFZaxOEOE3GFQzdNbg-QFXeb9T3X6PTJCc3t-o7nJlOXOoW2P0dFYtOomkRhU8s12IRowT34C2WptvociJ9VdD-yLq4RrvM_2c2pN0rCCqrZ46FiEPKxwEvWhzk4g?tknfv=ce105e2c0-d7bb-48ab-abb5-83a3f4ebp99pvv5">
                    <i className="bi bi-file-earmark-arrow-down"></i> Liste des panneaux PV acceptés par l’ANME</a>
                    </h4>

                    <div style={{height:"50px"}}></div>

                    <h4 ><a target="_blank" className="text-primary" rel="noopener noreferrer" href="http://www.steg.com.tn//$J@5YyQQEwCgRwELUhY-RBYYUICHTNf_22LiHrdZygBGQ36XrHyGo9vQhHaReR9soiTBdAZ_Dv-ZO03jGKkCtQDC1RUVH3rjT06SQ?tknfv=ce105e2c0-d7bb-48ab-abb5-83a3f4ebp99pvv5">
                    <i className="bi bi-file-earmark-arrow-down"></i> Liste des onduleurs acceptés par la STEG pour le raccordement au réseau BT</a>
                    </h4>

                </div>
            </div>
        </div>
    </div>
    );
}