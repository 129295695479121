import React from "react";
import carouselPicture5 from '../../assets/img/carousel-5.png'
import carouselPicture6 from '../../assets/img/carousel-6.png'
import carouselPicture7 from '../../assets/img/carousel-7.png'
import Carousel from 'react-bootstrap/Carousel'


export default function CarsouselPub(props) {
  return (
    <div className="container-fluid p-0 pb-5 " id="carousel_component" >
      
    <Carousel >
       
        <Carousel.Item  className="owl-carousel-item position-relative"  >
            <img className="img-fluid" src={carouselPicture5} alt="" ></img>
            <div className="owl-carousel-inner">
                <div className="container">
                    <div className="row justify-content-start">
                        <div className="col-10 col-lg-8">
                            <h1 className="display-2 text-white animated slideInDown">Universal Energy intervient meme à Lomé, Togo</h1>
                            <p className="fs-5 fw-medium text-white mb-4 pb-3 pt-5">Nous suivons la soleil, nous sommes ou elle brille. En effet Togo est un pays généralement ensoleillé et l'exploitation solaire est son atout  et nous avons déjà intervenu à Lomé pour un projet de site isolé.</p>
                           
                        </div>
                    </div>
                </div>
            </div>
        </Carousel.Item>
        <Carousel.Item className="owl-carousel-item position-relative"  >
            <img className="img-fluid" src={carouselPicture6} alt="" ></img>
            <div className="owl-carousel-inner">
                <div className="container">
                    <div className="row justify-content-start">
                        <div className="col-10 col-lg-8">
                            <h1 className="display-2 text-white animated slideInDown">Universal Energy s'interesse au energies verte</h1>
                            <p className="fs-5 fw-medium text-white mb-4 pb-3 pt-5">L'energie solaire est notre atout. En plus, nous travaillons aussi sur l'exploitation des energie hydrolique et aerienne. Toutes nos projet sont bien étudié et conçue exactement pour subvenir les besoins de clients et atteindre ses objectifs energétique. </p>
                           
                        </div>
                    </div>
                </div>
            </div>
        </Carousel.Item>
        <Carousel.Item  className="owl-carousel-item position-relative">
            <img className="img-fluid" src={carouselPicture7} alt="" ></img>
            <div className="owl-carousel-inner">
                <div className="container">
                    <div className="row justify-content-start">
                        <div className="col-10 col-lg-8">
                            <h1 className="display-2 text-white animated slideInDown">Universal energy au Maghreb</h1>
                            <p className="fs-5 fw-medium text-white mb-4 pb-3 pt-5">Nous sommes à l'écoute pour toutes les demandes d'installation photovoltaique au Maghreb entier, Nous intervenons sur place et assez rapidement. Vous faire profiter de la soleil est notre spécialité.</p>
                           
                        </div>
                    </div>
                </div>
            </div>
        </Carousel.Item>
    </Carousel>
</div>
    );
}
