import React, {useState,useRef,useEffect} from "react";
import {Row,Col,Modal,Container,Button,Card}  from 'react-bootstrap';
import { AdminService } from "../../../../../services/admin.service";
import useAuth from "../../../../../services/authentication/useAuth";

export default function AdminContact(props){
    const mountedRef = useRef(true)
    const [search,setSearch] = useState("")
    const [contactList,setContactList] = useState([])
    const [modalShow, setModalShow] = useState(false);
    const [contactDetails,setContactDetails] = useState({})
    const auth = useAuth();
    const handleSearch = (e)=>{
        setSearch(e.target.value.toLowerCase())
    }
    const handleContactDetails = (id) => {
        const item = contactList.find((item)=> item._id === id)
        setContactDetails(item)
        setModalShow(true)
        if(!item.state.seen.includes(auth.user))
            AdminService.openContactListDetails({id:id,username:auth.user}).then(res=>{
                if(res.success && res.success === 200){
                    setContactList(contactList.map(oldItem =>{
                        if(item._id === oldItem._id)
                            oldItem.state.seen.push(auth.user)
                        return oldItem
                    }))
                }
            })
    }

    const handleContactDelete = () =>{
        setContactList(contactList.filter(oldItem =>{
            if(oldItem._id !== contactDetails._id)
                return oldItem;
            return false;
        }))
        setModalShow(false)
    }

    useEffect(()=>{
        AdminService.getContactList().then((res)=>{
            if(res.length !== 0 ){
                if (!mountedRef.current) 
                    return null
                setContactList(res.message)
            }
        })
        return () => { 
            mountedRef.current = false
        }
    },[])
    return (
        <Container>
            <Row className="justify-content-center text-center">
                <Col  xs={'auto'}>
                    <input className="form-control" placeholder="Chercher un contact" onChange={handleSearch} title="search" style={{width:"200px"}} ></input>
                </Col>        
            </Row>
            <Row className="justify-content-center text-center">

                        <ContactListCard
                        search={search} 
                        contactList={contactList}
                        handleContactDetails={handleContactDetails}
                        /> 
                
            </Row> 
            <ContactDetails
                show={modalShow}
                onHide={() => setModalShow(false)}
                item={contactDetails}
                delete={handleContactDelete}
            />
        </Container>
    );
}

function ContactListCard(props){
    const auth = useAuth();
    return (props.contactList.filter((item,index)=>{
        if(props.search === ""){
            return item
        }else{
            if(item.number.toLowerCase().search(props.search) !== -1 
                || item.subject.toLowerCase().search(props.search) !== -1){
                return item
            }
        }
        return false;
    }).map((item,index)=>{

        return (
            <Col xs="auto" key={index}>

                    <Card  className="m-2" bg={item.state.seen.includes(auth.user) === true  ? "light" : "warning" } onClick={()=>props.handleContactDetails(item._id)}>
                        <Card.Body>
                        <Card.Title className="text-capitalize fw-normal">{"Contact N°" +item.number}</Card.Title>
                        
                        <span className="text-dark">
                            {item.subject}
                        </span>
                        </Card.Body>
                        <Card.Footer>
                        <small>
                            { item.createdIn? "Fait le " : ''}
                        <span className="text-primary">{ item.createdIn? item.createdIn.split(',')[0] : ''}</span>
                        { item.createdIn? " à " : ''}
                        <span className="text-primary"> { item.createdIn? item.createdIn.split(',')[1].split('.')[0] : ''}</span></small>
                        <hr></hr>
                        <small>
                            <span className="text-primary"> {item.state.seen.length} vu(s)</span>{' -- '}
                            <span className="text-primary">{item.state.progress.length === 0? "Non traité" : item.state.progress[item.state.progress.length-1]} </span>
                        </small>
                        </Card.Footer>
                        
                            
                    
                        
                    </Card>
               
            </Col>
        )
    }))
}

function ContactDetails(props) {
    const deleteContact = ()=>{
        AdminService.contactDelete(props.item._id).then(res=>{
            if(res.success && res.success === 200){
                props.onHide()
                props.delete()
            }
        })
    }
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter" >
            Contact
          </Modal.Title>
        </Modal.Header>
        <Modal.Body >
        <Container>
            <div className="row g-3">
                <div className="col-md-6">
                    <div className="form-floating">
                        <input type="text" className="form-control text-dark bg-light" defaultValue={"" || props.item.name} placeholder="Nom" readOnly></input>
                        <label htmlFor="name">Nom</label>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-floating">
                        <input type="email" className="form-control text-dark bg-light"  defaultValue={"" || props.item.email}  placeholder="Email" readOnly></input>
                        <label htmlFor="email">Email</label>
                    </div>
                </div>
                <div className="col-12">
                    <div className="form-floating">
                        <input type="text" className="form-control text-dark bg-light" defaultValue={"" || props.item.sujet}  placeholder="Sujet" readOnly></input>
                        <label htmlFor="subject">Sujet</label>
                    </div>
                </div>
                <div className="col-12">
                    <div className="form-floating">
                        <textarea className="form-control text-dark bg-light" placeholder="Leave a message here" defaultValue={"" || props.item.message}  style={{height: "100px"}} readOnly></textarea>
                        <label htmlFor="message">Message</label>
                    </div>
                </div>

            </div>
        </Container>
        </Modal.Body>
        <Modal.Footer>
            <Button onClick={deleteContact}  className='bg-danger' >Supprimer</Button>
            <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
}