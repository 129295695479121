import React from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';
import {Link} from "react-router-dom";

export default function SendCheck (props) {
    return (
      <>
      <Container className="py-5">
        <Row className="justify-content-center">
          {props.status === undefined ?
            <Col xs={12} className="text-center">
            <Spinner  animation="border" className="text-center" variant="success" /> 
            </Col>
          : ''}
          {props.status !== undefined ?
            <Col xs={12} className="text-center">
            {props.status === false ? <i className="bi bi-x-circle text-danger" style={{fontSize: "40px"}} ></i>  : <i className="bi bi-check-circle text-success" style={{fontSize: "40px"}} ></i> }
            {props.status === false ? <h3 className="text-danger text-center">Formulaire non envoyé!</h3> : <h3 className="text-success text-center">Demande envoyé avec succes</h3> }
            </Col>
          : ''}
          <Col lg="12"  className="text-center pt-5 ">
          <Link to="/"className="btn btn-outline-primary"  >Acceuil</Link>
          </Col>
        </Row>
      </Container>
      </>
    );
  }