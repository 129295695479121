
import React, { useState,  useEffect } from "react";
import parse from 'html-react-parser';
import { PublicService } from "../../services/public.service";


export default function CaptchaFormulaire (props) {
    const [svg,setSvg] = useState(<div></div>)
    useEffect(()=>{
      PublicService.getCaptcha().then((res)=>{setSvg(res)})
    },[])
  
    const reload = ()=>{
      PublicService.getCaptcha().then((res)=>{setSvg(res)})
    }
    return (
    <>
      <h4 className="mb-3 text-center">Captcha</h4> 
      <div className="mb-5">
        <div className="row justify-content-center">
          <div className='col-auto text-center'> 
            {parse(svg + "")}
            <br></br>
            <input onBlur={(e)=>props.handleCaptcha(e.target.value)}></input> <button onClick={reload}>reload</button>
          </div>
        </div>
      </div>
    </>)
}