import React from "react";
import gallery1 from "../../assets/img/gallery-1.jpg"
import gallery2 from "../../assets/img/gallery-2.jpg"
import gallery3 from "../../assets/img/gallery-3.jpg"
import gallery4 from "../../assets/img/gallery-4.jpg"
import gallery5 from "../../assets/img/gallery-5.jpg"
import gallery6 from "../../assets/img/gallery-6.jpg"
import StegLogo from "../../assets/img/steg.png"
import AnmeLogo from "../../assets/img/anme.png"
import AtfpLogo from "../../assets/img/atfp.png"

import {
    Link
} from "react-router-dom";


export default function Footer(props) {
  return (
      <>
  
    <div className="container-fluid bg-dark text-body footer mt-5 pt-5 wow fadeIn" data-wow-delay="0.1s">
    <div className="container py-5">
        <div className="row g-5">
            <div className="col-lg-3 col-md-6">
                <h5 className="text-white mb-4">Address</h5>
                <p className="mb-2"><i className="fa fa-map-marker-alt me-3"></i>12 Av Farhat Hached, 4116 Midoun-Djerba, Tunisie</p>
                <p className="mb-2"><i className="fa fa-phone-alt me-3"></i>+216 75 733 119</p>
                <p className="mb-2"><i className="fa fa-envelope me-3"></i>contact@universal-energy.tn</p>
                <div className="d-flex pt-2">
                    <Link className="btn btn-square btn-outline-light btn-social" to="https://www.facebook.com/universal.energy.tn"><i className="fab fa-facebook-f"></i></Link >
                </div>
            </div>
            <div className="col-lg-3 col-md-6">
                <h5 className="text-white mb-4">Quick Links</h5>
                <Link className="btn btn-link" to="/">Acceuil</Link >
                <Link className="btn btn-link" to="/contact">Contact</Link >
                <Link className="btn btn-link" to="/service">Service</Link >
                <Link className="btn btn-link" to="/terms">Termes & Conditions</Link >
                <Link className="btn btn-link" to="/download">Téléchargement</Link >
            </div>
            <div className="col-lg-3 col-md-6">
                <h5 className="text-white mb-4">Project Gallery</h5>
                <div className="row g-2">
                    <div className="col-4">
                        <img className="img-fluid rounded" src={gallery1} alt=""></img>
                    </div>
                    <div className="col-4">
                        <img className="img-fluid rounded" src={gallery2}  alt=""></img>
                    </div>
                    <div className="col-4">
                        <img className="img-fluid rounded" src={gallery3}  alt=""></img>
                    </div>
                    <div className="col-4">
                        <img className="img-fluid rounded" src={gallery4}  alt=""></img>
                    </div>
                    <div className="col-4">
                        <img className="img-fluid rounded" src={gallery5}  alt=""></img>
                    </div>
                    <div className="col-4">
                        <img className="img-fluid rounded" src={gallery6}  alt=""></img>
                    </div>
                </div>
            </div>
            <div className="col-lg-3 col-md-6">
                <div className="row">
                    <div className="col-md-12 col-12 text-center">
                        <h5 className="text-white mb-4">Nos Partenaires</h5>
                    </div>
                    <div className="col-4 col-md-4 col-xl-6  col-lg-6 col-xxl-4 pt-3">
                        <div className="d-flex  ">
                            <img src={StegLogo} style={{width:"120px",height:"80px"}} alt="no found"></img>   
                        </div>
                    </div>
                    <div className="col-4 col-md-4 col-xl-6 col-lg-6 col-xxl-4 pt-3">
                        <div className="d-flex justify-content-center ">
                            <img src={AnmeLogo} style={{width:"100px",height:"80px"}} alt="no found"></img>
                        </div>
                    </div>
                    <div className="col-4 col-md-4 col-xl-12 col-xxl-4 col-lg-12 pt-3">
                        <div className="d-flex justify-content-center">
                            <img src={AtfpLogo} style={{width:"100px",height:"80px"}} alt="no found"></img>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div className="container">
        <div className="copyright">
            <div className="row">
                <div className="col-md-6 text-center text-md-start mb-3 mb-md-0">
                    &copy; <Link to="#">Universal Energy</Link >, All Rights Reserved.
                </div>
            </div>
        </div>
    </div>
</div>
</>
    );
}


  