import React, {  useEffect, useState} from "react";
import { VectorMap } from '@south-paw/react-vector-maps';
import tunisiaMap from "../../assets/map/tunisia.json"
import styled from 'styled-components'
import Background from "./Background"


const MapStyle = styled.div`
svg {
  stroke: #fff;

  // All layers are just path elements
  path {
    fill: #32C36C;
    cursor: pointer;
    outline: none;

    // When a layer is hovered
    &:hover {
      fill: yellow;
    }

    // When a layer is focused.
    &:focus {
      fill: rgba(168,43,43,0.6);
    }

    // When a layer is 'checked' (via checkedLayers prop).
    &[aria-checked='true'] {
      fill: rgba(56,43,168,1);
    }

    // When a layer is 'selected' (via currentLayers prop).
    &[aria-current='true'] {
      fill: #32C36C;
    }

    // You can also highlight a specific layer via it's id
    &[id="nz-can"] {
      fill: rgba(56,43,168,0.6);
    }
  }
}

.two {
  transition: all 2s ease-in-out 0.5s;
  -webkit-transition: all 2s ease-in-out 0.5s;
}
.grow {
  transform:  scale(1);
  -ms-transform: translate(840,1082) scale(3);
  -webkit-transform: translate(840,1082) scale(3);
}
`;
const Pin = styled.div`
position:absolute;
cursor: not-allowed;
.vl {
  display:none;
  margin-left: -15px;
  margin-top:-230px;
  position:absolute;
  transform-origin: 50% 50%;
  transform: rotate(45deg);
  border-left: 1px solid blue;
  height: 75px;
  width: 150px;
}
.hl {
  display:none;
  margin-left: 34px;
  position: absolute;
  margin-top:-272px;
  
  border-top: 1px solid blue;
  height: 1px;
  width: 150px;
}

.text {
  position:absolute;
  margin-top:-285px;
  font-size:18px;
  width:250px;
  margin-left: 200px;
  color:#000000;
  font-weight: bold;
}
@media only screen and (max-width: 535px)   {
  .text {
    width:150px;
  }
}
font-family: "Helvetica Neue Light", "HelveticaNeue-Light", "Helvetica Neue", Calibri, Helvetica, Arial, sans-serif;
`;

const UnivNabeul = styled.div`
  position: relative;
  margin-left:-70px;
  margin-top:-82px;
  pointer-events : none;

`;

const UnivDjerba =styled.div`
  position: relative;
  margin-left:-65px;
  margin-top:75px;
  pointer-events : none;

`;

const UnivTataouine = styled.div`
  position: relative;
  margin-left:-100px;
  margin-top:170px;
  pointer-events : none;

`;

const WeatherStyle = styled.div`
position:relative;
cursor: default;
.map-pin {

  width: 65px;
  height: 55px;
  position: absolute;
  top: -259px;
  left: -40px;
  background-size: 40px 40px;
  background-repeat: no-repeat;
  text-align: center;
  background-position: 3px 5px;
  color: #888;
  font-weight: 100;
  font-size: 7px;
  animation-name: move;
   animation-duration: 10s;
   animation-iteration-count: infinite;
}
  
.map-pin > span {
  font-family: "Helvetica Neue Light", "HelveticaNeue-Light", "Helvetica Neue", Calibri, Helvetica, Arial, sans-serif;
  position: absolute;
  bottom: -0px;
  width: 100%;
  font-weight: bold;
  color:#050505;
  text-align: center;
  font-size: 15px;
  left: -5px;
}

@keyframes move {
  0%   {background-position:  5px 5px;}
  25%  {background-position: -4px 5px;}
  50%  {background-position:  5px 5px;}
  75%   {background-position: 15px 5px;}
  100% {background-position:  5px 5px;}
}

#kr {
  margin-left:130px;
  margin-top:-59px;
  pointer-events : none;
}
#tu {
  margin-left:148px;
  margin-top:-130px;
  pointer-events : none;
}
#kf {
  margin-left:75px;
  margin-top:-90px;
  pointer-events : none;
}
#kb {
  margin-left:85px;
  margin-top:65px;
  pointer-events : none;
}
#me {
  margin-left:185px;
  margin-top:80px;
  pointer-events : none;
}
`;
export default function Map(props) {
  

  return (
    <>
    
    <div className="container-fluid  text-center bg-light  position-relative"  id="service-component" >
    <Background style={{height:"550px",background:"#F6F7F8"}} />
   {/* <h1 className="mb-4 text-center text-primary fw-bold font-monospace" style={{textShadow: "0px 5px 5px #ffc107",color:"#318af2",position:"absolute",top:"4%",width:"100%"}}> Universal Energy Map </h1>
       */}
       <div className="row justify-content-center d-flex" style={{position:"absolute",top:"13%",width:"100%"}}>
    
      <div className='col-xl-4 col-lg-0 pt-3 d-none d-xl-block' >
        <h1 className="pub color-animation">مادام الشمس بلاش، الفاتورة علاش؟ </h1>
        <div className="row justify-content-center pt-5">
          <div className="col-auto">        
            <h3 className="sous_pub pt-3 text-end px-4">أحدث تكنولوجيا الألواح الشمسية-</h3>
            <h3 className="sous_pub pt-2 text-end px-4">خدمة ما بعد البيع مجانية لمدة عامين-</h3>
            <h3 className="sous_pub pt-2 text-end px-4">تسهيلات في الدفع-</h3>
          </div>
        </div>
      </div>
      
      
      <div className="col-auto">
        <MapStyle>
          <JsonToSvg/>
          <UnivLocationPins/>
            <WeatherPins/>
        </MapStyle>
       {/* <MapStyle>
          <VectorMap {...map} layerProps={{onClick}} />
          <UnivLocationPins/>
          <WeatherPins/>
        </MapStyle>*/}
      </div>
      <div className='col-xl-4' >
      </div>
    
    </div>
      
    </div>
    
    </>
    );
}


function JsonToSvg(){
  const [map,setMap] = useState(tunisiaMap);

  
  const onClick = ({ target }) => {

    var svgRect = { x: 406, y: 146.5, width: 200, height: 400, top: 146.5, right: 606, bottom: 546.5, left: 406 } 
    var objRect = target.getBoundingClientRect()
   /* const viewBox = map.viewBox.split(" ");
    var svgRect = { x: 406, y: 146.5, width: 200, height: 400, top: 146.5, right: 606, bottom: 546.5, left: 406 } 
    var objRect = target.getBoundingClientRect()
    var scaleX = objRect.width / 200 , scaleY = objRect.height / 400;
    var newX = (svgRect.left - objRect.left) / scaleX;
    var newY = (svgRect.top - objRect.top) / scaleY;
    var xScale = svgRect.width / objRect.width;
    var yScale = svgRect.height / objRect.height;
    console.log("matrix(" + xScale + " 0 0 " + yScale + " " + newX * xScale + " " + newY * yScale + ")")*/
    const clickedLayer = map.layers.filter((elem)=>{
      if(elem.name === target.attributes["aria-label"].value){
        console.log(objRect)
        console.log(svgRect)
        elem.style= {transform:"translate(0px, 529px)"}//{transformOrigin:  (objRect.x + objRect.width/2) + "px " + (objRect.y + objRect.height/2) +"px" }  
        elem.transform = ""
        return elem
      }
      return false;
    })
    if(clickedLayer.length !== 0){
      setMap({...map, layers :clickedLayer})
    }
    }

    useEffect(()=>{
     // console.log(map)
    },[map])
  return(
    <svg
      xmlns="http://www.w3.org/2000/svg"
      aria-label="Tunisie"
      height={400} 
      width={200}
      viewBox="0 0 900.0 1954.3544733545057"
    >
      <g>
        {
          map.layers.map((item,index)=>{
            return <path id={item.id} aria-label={item.name} d={item.d} key={index} onClick={onClick}></path>
            
          })
        }
      </g>
    </svg>
  )
}

function UnivLocationPins(){

  return (
    <>
        <Pin>
          <UnivNabeul>
            <div  id='univ-nabeul' >
              <div className='hl'></div>
              <div className='vl'></div>
              <div className='text'><i className='fa fa-map-marker-alt me-2' ></i>Universal energy à Nabeul</div>
            </div>
          </UnivNabeul>
        </Pin>
        <Pin>
        <UnivDjerba>
            <div  id='univ-djerba'>
              <div className='hl'></div>
              <div className='vl'></div>
              <div className='text'><i className='fa fa-map-marker-alt me-2 '></i>Universal energy à Djerba</div>
            </div>
          </UnivDjerba>
        </Pin>
        <Pin>
          <UnivTataouine>
          <div id='univ-tataouine'>
            <div className='hl'></div>
            <div className='vl'></div>
            <div className='text'><i className='fa fa-map-marker-alt me-2 '></i>Universal energy à Tataouine</div>
          </div>
          </UnivTataouine>
        </Pin>
    </>
  );
}

function WeatherPins(){
  const [weatherPins,setWeatherPins] = useState([ 
    {
      id:"kr",
      url:"://api.weatherapi.com/v1/current.json?key=225b3f7c578c481c9f4134507223006&q=Kairouan&aqi=yes"
    },
    {
      id:"tu",
      url:"://api.weatherapi.com/v1/current.json?key=225b3f7c578c481c9f4134507223006&q=Tunis&aqi=yes"
    },
    {
      id:"kb",
      url:"://api.weatherapi.com/v1/current.json?key=225b3f7c578c481c9f4134507223006&q=kebili&aqi=yes"
    },
    {
      id:"me",
      url:"://api.weatherapi.com/v1/current.json?key=225b3f7c578c481c9f4134507223006&q=medenine&aqi=yes"
    },
    {
      id:"kf",
      url:"://api.weatherapi.com/v1/current.json?key=225b3f7c578c481c9f4134507223006&q=el kef&aqi=yes"
    },
  ])
  const [isLoaded, setIsLoaded] = useState(false);

  const getWeatherInfo = async ()=>{
      
    try{
      await Promise.all( weatherPins.map( (item,index)=>{
       return  fetch(window.location.protocol.replace(':','') + item.url)
        .then(response => response.json())
        .then(data => {
          setWeatherPins((a)=>[...a,{id:item.id,url:item.url,temp:data.current.temp_c,iconUrl:(window.location.protocol + data.current.condition.icon )}])
          if(index === weatherPins.length -1 )
            setIsLoaded(true)
        })
      }))
    }catch(e){
      console.log(e)
    }
  }
  useEffect(()=>{
    getWeatherInfo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])
  


  return(
    <WeatherStyle>
      { 
         isLoaded === false ? '' : weatherPins.map((item)=>{          
          if(item.temp){
            return <div key={item.id} className='map-pin' id={item.id} style={{backgroundImage:"url("+item.iconUrl+")"}} ><span>{item.temp}&#176; </span></div>
          }else 
            return ''
        }) 
      }
    </WeatherStyle>
  );
}