import React, {useState,useRef,useEffect} from "react";
import {Row,Col,Container,Button,Card}  from 'react-bootstrap';
import { AdminService } from "../../../../../services/admin.service";


export default function AdminUser(){
    const mountedRef = useRef(true)
    const [search,setSearch] = useState("")
    const [userList,setUserList] = useState([])
    const [show,setShow] =useState(false)
    const [userDetails,setUserDetails] = useState({})
    const handleSearch = (e)=>{
        setSearch(e.target.value.toLowerCase())
        if(show === true)
            setShow(!show)
    }
    const handleAdminUserDetails = (username)=>{
        setShow(true)
        setUserDetails(userList.find((item)=> item.username === username))
    }
    const updateUser = (user) => {
        var users = userList;
        users[userList.findIndex((item)=> item.username === user.username)] = user;
        setUserList(users);
        setUserDetails(userList.find((item)=> item.username === user.username))
    }
    useEffect(()=>{
        AdminService.getUsersList().then((r)=>{
            if (!mountedRef.current) 
                return null
            setUserList(r)
        })

        
        return () => { 
            mountedRef.current = false
        }
    },[])
    return (<>
    <Container>
    <Row className="justify-content-center text-center">
        <Col  xs={'auto'}>
            <input className="form-control" placeholder="Chercher un utilisateur" onChange={handleSearch} title="search" style={{width:"200px"}} ></input>
        </Col>        
    </Row>
    <Row className="justify-content-center text-center">
        { !show? 
                <AdminUserListCard 
                    search={search} 
                    userList={userList}
                    handleAdminUserDetails={handleAdminUserDetails}
                />
        : 
        <AdminUserDetails userDetails={userDetails} setShow={setShow} updatedUser={updateUser}/>
        }
    </Row> 
    </Container>
    
    </>);
}

function AdminUserListCard(props) {

    const handleAdminUserDetails = props.handleAdminUserDetails;

    return (
      props.userList.filter((item)=>{
        if(props.search === ""){
            return item
        }else{
            if(item.username.search(props.search) !== -1 
                || item.nom.search(props.search) !== -1
                || item.prenom.search(props.search) !== -1
                || item.phone.search(props.search) !== -1
                || item.email.search(props.search) !== -1){
                return item
            }
        }
        return false;
      }).map((item,index)=>{
      return(
        <Col xs="auto" key={index}>
            <Card   className="m-2" bg={item.roles[0].name === "client" ? "light" : "warning"} onClick={()=>handleAdminUserDetails(item.username)}>
                <Card.Body>
                    <Card.Title className="text-capitalize fw-normal">{item.nom + " " + item.prenom}</Card.Title>                       
                </Card.Body>
                <Card.Footer>
                    <small className="text-dark">Crée par : <span className="text-primary">{item.createdBy[0].username}</span> </small>
                  
                  <hr></hr>
                  <small>
                  { item.createdIn? " le " : ''}
                  <span className="text-primary">{ item.createdIn? item.createdIn.split(',')[0] : ''}</span>
                  { item.createdIn? " à " : ''}
                  <span className="text-primary"> { item.createdIn? item.createdIn.split(',')[1].split('.')[0] : ''}</span></small>
                </Card.Footer>
            </Card>
            </Col>)
      })
    );
}

function AdminUserDetails (props){
    const closeAdminUserDetails = ()=>{props.setShow(false)}
    const [user,setUser] = useState(props.userDetails)
    const [edit,setEdit] = useState({synergy:false,growatt:false})
    const [connection,setConnection] = useState(false)
    const [provider,setProvider] = useState("")
    
    const handleInput = (e)=>{
        var target = e.target.title.split('_');
        const newProvider = user.providers
        newProvider[target[0]][target[1]] = e.target.value
        setUser((prev)=> (
            {...prev,
                providers: newProvider
            }))
    }

    const handleEdit = (e)=>{
        if(edit[e] === false)
            setEdit((prev)=>({...prev,[e]:!edit[e]}))
    }

    const handleConnect = (provider)=>{
        setProvider(provider)
        setConnection(true)
        setEdit((prev)=>({...prev,[provider]:false}))
    }

    useEffect(()=>{
        if(connection){
            var options = user.providers[provider]
            options.username = user.username;
            options.provider = provider;
            AdminService.updateProvider(options).then((r)=>{
                const newProvider = user.providers;
                if(r.message && r.message === 200)
                    newProvider[provider].connected = true
                else
                    newProvider[provider].connected = false
                
                    setUser((prev)=> (
                        {...prev,
                        providers:newProvider}))       
            })
        }

        setConnection(false)
        
        return ()=>{
            props.updatedUser(user)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[connection])
    return(
        <>
        <i className="bi bi-x text-dark text-end" onClick={closeAdminUserDetails} style={{fontSize:"38px"}}></i>
        
        <div className="text-center mx-auto mb-5 pt-3" style={{maxWidth: '600px'}}>
            <h1 className="mb-4" style={{textShadow: "0px 5px 5px #ffc107",color:"#318af2"}}>{user.roles[0].name} </h1>
        </div>
        <Col md={8} xs={12} >
            
            <Card>
                <Card.Header>
                    <h4 className="text-dark">
                            Coordonnée
                    </h4></Card.Header>
                <Card.Body>
                <Row className="justify-content-center">
                            <Col xs={6}>
                                <div className="mb-3 row ">
                                    <label className="col-sm-12 col-form-label text-nowrap">Nom</label>
                                    <div className="col-sm-12">
                                        <input type="text" readOnly className="form-control-plaintext text-center text-dark" title={user.nom +"_pwd"}  onChange={handleInput}  value={user.nom}></input>
                                    </div>
                                </div>
                            </Col>

                            <Col xs={6}>
                                <div className="mb-3 row ">
                                    <label className="col-sm-12 col-form-label text-nowrap">Prenom</label>
                                    <div className="col-sm-12">
                                        <input type="text" readOnly className="form-control-plaintext text-center text-dark" title={user.prenom +"_pwd"}  onChange={handleInput}  value={user.prenom}></input>
                                    </div>
                                </div>
                            </Col>

                            <Col xs={6}>
                                <div className="mb-3 row ">
                                    <label htmlFor="staticEmail" className="col-sm-12 col-form-label text-nowrap">Email</label>
                                    <div className="col-sm-12">
                                        <input type="text" readOnly className="form-control-plaintext text-center  text-dark"  value={user.email}></input>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={6}>
                                <div className="mb-3 row ">
                                    <label htmlFor="staticEmail" className="col-sm-12 col-form-label text-nowrap">Tel</label>
                                    <div className="col-sm-12">
                                        <input type="text" readOnly className="form-control-plaintext text-center text-dark" title={user.username +"_pwd"}  onChange={handleInput}  value={user.phone}></input>
                                    </div>
                                </div>
                                
                            </Col>
                            
                        </Row>
                </Card.Body>
            </Card>
            <div  className="pt-5"></div>
            { user.providers?  <> 
                <Card>
                    <Card.Header>
                        <h4 className="text-dark ">
                            Providers
                        </h4>
                    </Card.Header>
                    <Card.Body>
                        <Row className="justify-content-center pt-1">
                            { Object.keys(user.providers).map((item,index)=>{
                                var providerName = item;

                            if(user.providers[providerName]?.id !== undefined &&user.providers[providerName]?.pwd !== undefined )
                            return ( 
                                <Col xs={'auto'} key={index} >
                                    <Card style={{ width: '13rem' }}  border={user.providers[providerName].connected === true ? (edit[providerName]? "dark" :"success") : (edit[providerName]? "dark":"danger")} onDoubleClick ={()=>handleEdit(providerName)}>
                                        <Card.Header className="fw-bolder text-capitalize text-dark">{providerName} </Card.Header>
                                        <Card.Body>
                                            <Row className="justify-content-center">
                                                <Col xs={'auto'}>
                                                    <div className="mb-3 row ">
                                                        <label htmlFor="staticEmail" className="col-sm-2 col-form-label text-nowrap">Id</label>
                                                        <div className="col-sm-10">
                                                            <input type="text" readOnly={edit[providerName]?false :true} className={edit[providerName]? "form-control-plaintext text-center text-dark border border-dark" :"form-control-plaintext text-center text-dark"}  title={providerName+"_id"} onChange={(e)=>{handleInput(e); setProvider(providerName)}} defaultValue={user.providers[providerName].id}></input>
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col xs={'auto'}>
                                                    <div className="mb-3 row ">
                                                        <label htmlFor="staticEmail" className="col-sm-2 col-form-label text-nowrap ">Pass </label>
                                                        <div className="col-sm-10">
                                                            <input type="text" readOnly={edit[providerName]?false :true} className={edit[providerName]? "form-control-plaintext text-center text-dark border border-dark" :"form-control-plaintext text-center text-dark"} title={providerName+"_pwd"} onChange={(e)=>{handleInput(e); setProvider(providerName)}} defaultValue={user.providers[providerName].pwd}></input>
                                                        </div>
                                                    </div>
                                                    
                                                </Col>
                                            </Row>
                                        </Card.Body>
                                        <Card.Footer className={user.providers[providerName].connected === true ? (edit[providerName]? "bg-light" :"bg-success") : (edit[providerName]? "bg-light":"bg-danger")}>
                                            {edit[providerName]? 
                                            <Button size="sm" onClick={()=>handleConnect(providerName)}>Se connecter</Button>
                                            :<span className="text-dark">{user.providers[providerName].connected ? 'On' : 'Off'}  {user.providers[providerName].connected ? <i className="bi bi-check-circle"></i> : <i className="bi bi-x-circle"></i>}</span> }
                                        </Card.Footer>
                                    </Card>
                                </Col>)

                            })}
                        </Row>
                    </Card.Body>
                </Card>
            
            
             </>  : '' }
            
        </Col>
        </>
    )
}