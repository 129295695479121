import React from "react";

export default function Profil() {

    return (
        <>
        <div>Profil</div>
        </>
        
    );
}