import React, {useEffect,useState}  from "react";
import useAuth from "../../../services/authentication/useAuth";
import Spinner from 'react-bootstrap/Spinner';
import {
  Navigate
} from "react-router-dom";

export default function Dashboard () {
  const [role,setRole] = useState(undefined)
  const auth = useAuth();
  useEffect(()=>{
    if(auth.role && auth.role[0] !== undefined){
        setRole(auth.role[0])
    }
  },[auth])
  if(role === undefined)
    return ( <Loading/>)
  else if(role === "ROLE_CLIENT") {
    return <Navigate  to="/user/dashboard"/>;
  }else if ( role === "ROLE_ADMIN") {
    return <Navigate  to="/user/admin/dashboard"/>;

  }
  

}


function Loading(){
  return(
    <div className="d-flex justify-content-center">
      <Spinner animation="border" role="status" >
          <span className="visually-hidden">Loading...</span>
      </Spinner>
    </div>
  )
}


