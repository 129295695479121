import React, { useEffect,useState } from "react";
import { useParams, useNavigate} from 'react-router-dom';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Filler,
    Legend,
  } from 'chart.js';
  import { Line,Bar } from 'react-chartjs-2';
import { UserService } from "../../../services/user.service";
import {Spinner,Container,Row,Col,Button} from 'react-bootstrap';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';


export default function PlantDetails(){
  return (
    <>
      <PlantDetailsTotal/>
      <Chart/>
    </>
  )
}
function PlantDetailsTotal(){
  let navigate = useNavigate();
  let { plantId } = useParams();
  const [plantTotal,setPlantTotal] = useState({})
  useEffect(()=>{
    UserService.getUserPlantList((r)=>{
      if(r.status !== undefined || r.status !== false ){
        setPlantTotal(r.filter((item) => item.AutoID === (plantId).toString())[0])
      }
      })
      // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])
  return (
    <>
      <Container>
      <Row className="justify-content-center">
        <i className="bi bi-x text-dark text-end" onClick={()=>{navigate("/user")}}  style={{fontSize:"38px"}}></i>
      </Row>
    </Container>

    <Container>
      
            <div className="text-center mx-auto mb-5" style={{maxWidth: '600px'}}>
                <h1 className="mb-4" style={{textShadow: "0px 5px 5px #ffc107",color:"#318af2"}}>{plantTotal.GoodsTypeName} </h1>
            </div>
    </Container>
    
     <Container fluid className=" bg-light ">
      <Row className="justify-content-center">
        
        <TotalDashboardHeaderElementV2 
            name="Power"
            value={(plantTotal.CurrPac/1000).toFixed(2) + ' W'}
        />
        <TotalDashboardHeaderElementV2 
            name="E-Today"
            value={(plantTotal.EToday/1000).toFixed(2) + ' kwh'}
        />
        <TotalDashboardHeaderElementV2 
            name="E-Total"
            value={(plantTotal.ETotal/1000).toFixed(2) + 'k kwh'}
        />
        <TotalDashboardHeaderElementV2 
            name="Total Income"
            value={((plantTotal.ETotal/1000) * 0.475).toFixed(3) + " Dt"}
        />
        <TotalDashboardHeaderElementV2 
            name="H-Total"
            value={(plantTotal.Htotal/1000).toFixed(2) + 'k Hrs'}
        />
        <TotalDashboardHeaderElementV2 
            name="Capacity"
            value={(plantTotal.GoodsKWP/1000).toFixed(2) + 'k Wp'}
        />
      </Row>
    </Container>
    </>
  );
}

function TotalDashboardHeaderElementV2(props){
  return (
    <Col className=" m-1 position-relative mx-auto text-center" xs={10} sm={5} md={2} lg={1}  xxl xl>
      <span className="pt-4 text-dark text-nowrap text-center">{props.name}</span> <br></br> <span className="text-primary text-nowrap text-center">{props.value}</span>
    </Col>
  );
}

function Chart(){
    let { provider, plantId } = useParams();
    const [lineData,setLineData] = useState({})
    const [date, setDate] = useState(new Date());
    const [calendarMode,setCalendarMode] = useState("month")
    const [options,setOptions] = useState({})
    const [showDate,setShowDate] = useState(false)
    ChartJS.register(
      CategoryScale,
      LinearScale,
      PointElement,
      LineElement,
      BarElement,
      Title,
      Tooltip,
      Filler,
      Legend
    );

    const inTimetoTunisiaInTime = (today,data) =>{
      const pad = (d) => {
        return (d < 10) ? '0' + d.toString() : d.toString();
      }
      const tunisUTC = new Date(new Date().toLocaleString('en-US', { timeZone: 'Africa/Tunis'  }))
      const parisUTC = new Date(new Date().toLocaleString('en-US', { timeZone: 'Europe/Paris'  }))
      const diffUTC =  parisUTC.getUTCHours() - tunisUTC.getUTCHours()  ;
      return data.filter((item)=>{
        if(today)
          return parseInt(item.inTime.split(':')[0]) < parisUTC.getHours() || ((item.inTime.split(':')[0]) === parisUTC.getHours() && parseInt(item.inTime.split(':')[1]) <= parisUTC.getMinutes()  )
        else  
          return item;
        }).map((item)=>{
          return item.inTime = (pad(parseInt(item.inTime.split(':')[0]) !== 0 ? (parseInt(item.inTime.split(':')[0])  - diffUTC)%24 : (24 - diffUTC) % 24 ) + ":" + item.inTime.split(':')[1])
      })
    }
    const controlDatePicker = ()=>{
      setShowDate(!showDate)
    }
    const handleCalendarMode = (e)=>{
      setCalendarMode(e.target.title)
    }
    const handleDate = (e)=>{
      setDate(e)
      controlDatePicker()
    }
    useEffect(()=>{

      var mode = calendarMode==="month"?"day" : calendarMode ==="decade" ? "year" : "month";
      var dateWillTransmitted = date.getFullYear() + ""
      if(mode === "month")
        dateWillTransmitted= dateWillTransmitted + "-"+("0"+parseInt(date.getMonth()+1)).slice(-2)
      if(mode ===  "day")
        dateWillTransmitted= dateWillTransmitted +"-"+("0"+parseInt((date.getMonth() + 1))).slice(-2)+"-"+("0"+parseInt(date.getDate())).slice(-2)
      var today = new Date()
      if(today.getFullYear() === date.getFullYear() 
        && date.getMonth() === today.getMonth()
        && date.getDate() === today.getDate()
        && calendarMode === "month")
          today = true;
      else
        today = false;
      UserService.getPlantDetail(provider,plantId ,dateWillTransmitted ,mode,today,(r)=>{
        if(r.status !== undefined || r.status !== false){
          var labels;
          if(calendarMode === "month"){

            labels = inTimetoTunisiaInTime(today,r.data);
            setLineData({
              labels,
              datasets: [
                {
                  fill: true,
                  label: 'Production',
                  data: r.data.map((item,index)=> item.pac  ).slice(0,labels.length),
                  borderColor: 'rgb(53, 162, 235)',
                  backgroundColor: 'rgba(53, 162, 235, 0.5)',
                  lineTension: 0.5,        
                  radius: 3     
                },
              ],
            })
            setOptions({
              scales: {
                y: {
                    min: 0,
                    ticks : {
                      callback: function(value) {
                        return `${value} kW`;
                      }
                    }
                },
                x : {
                  grid: {
                    display: false
                  }
                }
              },
              responsive: true,
              plugins: {
                legend: {
                  position: 'top',
                }
              },
            })
          }else if (calendarMode === "year"){
            setOptions({
              scales: {
                y: {
                    min: 0,
                    ticks : {
                      callback: function(value) {
                        return `${value} kWh`;
                      }
                    }
                },
                x : {
                  grid: {
                    display: false
                  }
                }
              },
              responsive: true,
              plugins: {
                legend: {
                  position: 'top',
                },
                title: {
                  display: true,
                  text: "production de " + date.toLocaleDateString("fr-FR", {  year: 'numeric', month: 'long'}),
                },
              },
            })
            labels = r.data.map(item=> item.inDate.split('-')[2])
            setLineData({
                labels,
                datasets: [
                  {
                    label: 'Production',
                    data: r.data.map(item => item.pac),
                    backgroundColor: 'rgba(53, 162, 235, 0.5)',
                  },
                ],
            })
          }else{
            setOptions({
              scales: {
                y: {
                    min: 0,
                    ticks : {
                      callback: function(value) {
                        return `${value} kWh`;
                      }
                    }
                },
                x : {
                  grid: {
                    display: false
                  }
                }
              },
              responsive: true,
              plugins: {
                legend: {
                  position: 'top',
                },
                title: {
                  display: true,
                  text: "production de " + date.toLocaleDateString("fr-FR", {  year: 'numeric'}),
                },
              },
            })
            labels = r.data.map(item=> item.inMonth)
            setLineData({
                labels,
                datasets: [
                  {
                    label: 'Production',
                    data: r.data.map(item => item.pac),
                    backgroundColor: 'rgba(53, 162, 235, 0.5)',
                  },
                ],
            })
          }
        }
      })
      // eslint-disable-next-line react-hooks/exhaustive-deps
     },[date,calendarMode])  


    if(Object.keys(lineData).length === 0 || lineData.labels === undefined  ){
      return (
      <div className="d-flex justify-content-center">
        <Spinner animation="border" role="status" >
            <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>)
    }

 
    return (
      <Container>
        <Row className="justify-content-center p-4 ">
        <Col lg={"auto"} className="text-center">
            <Button size="sm" variant="outline-primary border-none" title="month" onClick={handleCalendarMode}>Jour</Button>{' '}
            <Button size="sm" variant="outline-primary" title="year" onClick={handleCalendarMode}>Mois</Button>{' '}
            <Button size="sm" variant="outline-primary" title="decade" onClick={handleCalendarMode}>Année</Button>{' '}
          </Col>
          <Col lg={"atuo"} className="text-center p-3">
          <Button variant="light" className='text-center text-dark' onClick={controlDatePicker}>
            {date.toLocaleDateString("fr-FR", { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}
          </Button>
          <div className="d-flex justify-content-center ">
            <Calendar onChange={handleDate}  className={!showDate? "visually-hidden position-absolute" : "position-absolute"} minDate={new Date(2020, 1, 1)}  value={date} 
              maxDetail={calendarMode}
              maxDate={new Date()} />
          </div>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col></Col>
            {calendarMode === "month"? 
              <Line options={options} data={lineData} />
            : 
            <Bar options={options} data={lineData} />}
        </Row>
      </Container>
    );

}