import React ,{useEffect,useState} from "react";
import { UserService } from "../../../../services/user.service";
import Table from 'react-bootstrap/Table';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {
  useNavigate
} from "react-router-dom";


export default function ClientDashboard (){
  const [plantList,setPlantList] = useState([]);


  
  useEffect(()=>{
    UserService.getUserPlantList((r)=>{
      setPlantList(r)
    })
  },[])

  return( 
    <>
        <TotalDashboardHeaderList plantList={plantList}/>
        <PlantList plantList={plantList}/>
    </>
  )
}
  
  
   function TotalDashboardHeaderList(props){
    const [total,setTotal] = useState({
      CurrPac : 0,
      EToday :0,
      Htotal:0,
      ETotal:0,
      IncomeTotal:0,
      AllLight:{green:0,yellow:0,red:0,gray:0}
    })

    useEffect(()=>{
      if(props.plantList.length > 0){
        const accTotal = props.plantList.reduce((acc,item)=>{
          acc.CurrPac = eval(acc.CurrPac) + eval(item.CurrPac)
          acc.EToday = acc.EToday+ item.EToday
          acc.ETotal = acc.ETotal + item.ETotal
          acc.AllLight.green =  acc.AllLight.green + item.AllLight.green
          acc.AllLight.yellow =  acc.AllLight.yellow + item.AllLight.yellow
          acc.AllLight.red =   acc.AllLight.red + item.AllLight.red
          acc.AllLight.gray =  acc.AllLight.gray + item.AllLight.gray
          return acc
        })
        if(accTotal.CurrPac)
          setTotal(accTotal)
      }
    },[props.plantList])
  
     const data = [
       {name:"Power", value:(total.CurrPac/1000).toFixed(2) + 'k W',icon:"bi bi-power",nameTotal:"E-total", valueTotal:(total.Htotal/1000).toFixed(2) + 'k Hrs'},
       {name:"E-Today", value:(total.EToday/1000).toFixed(2) +" kwh",icon:"bi bi-battery-charging",nameTotal:"E-Total", valueTotal:(total.ETotal/1000000).toFixed(2) +" MWh"},
       {name:"Daily income", value:((total.EToday/1000) * 0.471).toFixed(3) + " Dt",icon:"bi bi-wallet2",nameTotal:"Total income", valueTotal:(total.ETotal/1000* 0.471).toFixed(3) + " Dt"},
       {name:"Total plant", value:Object.keys(total.AllLight).reduce((t,item)=> t += total.AllLight[item],0) + " units",icon:"bi bi-pie-chart",nameTotal:"statistics", valueTotal:
       <div className="d-flex justify-content-center">
  {Object.keys(total.AllLight).map((item,index)=>{ return <div key={index}><i className="bi bi-lightbulb-fill" style={{color:item}}></i> {total.AllLight[item]}</div>})}</div> }
     ]
    return (
      <Container fluid className=" bg-light ">
        <Row className="justify-content-center">
          {data.map((item,index)=>{
            return <TotalDashboardHeaderElement 
            name={item.name} 
            value={item.value}
            icon={<i className={item.icon} style={{fontSize:"50px",color:"blue"}}></i>} 
            nameTotal={item.nameTotal} 
            valueTotal={item.valueTotal} 
            key={index}
            />
          })}
  
        </Row>
      </Container>
    );
  }
  
  function TotalDashboardHeaderElement(props){
    return (
      <Col className="border bg-white m-3" xs={10} sm={5} md={5} lg={5}  xxl xl>
        <Container  fluid>
          <Row className="justify-content-align">
            <Col xs sm md={8}> <h5 className="pt-2">{props.name}</h5>  <h6>{props.value}</h6></Col>
            <Col xs sm md={4} className="text-end">{props.icon}</Col>
          </Row>
          { props.nameTotal !== undefined ? 
          <div className="text-center">
            <hr></hr>
            <div className="d-flex justify-content-center align-items-center pb-3">
              <label className="fw-bolder text-dark">{props.nameTotal} : </label>
              <span className="text-dark"> {props.valueTotal}</span>
            </div>
            
          </div> : ''}
       </Container>
      </Col>
    );
  }
  
  
  
  
  function PlantList(props) {
    let navigate = useNavigate();

    const light = {1:"green",3:"red",2:"yellow",4:"grey"}
  
    const handleItem = (GroupAutoId,provider)=>{
      navigate("/user/plantDetails/" + provider + "/"+ GroupAutoId);
    }
  
    return (
      <Container className="pt-5">
        <Row className="justify-content-center">
          <Col  md lg xl xxl={10}>
            <Table  bordered responsive hover>
              <thead>
                <tr className="text-center text-primary">
                  <th>Satus</th>
                  <th>PlantName</th>
                  <th>E-Today</th>
                  <th>E-Total</th>
                  <th>Start Time</th>
                  <th>Update</th>
                  <th>Power</th>
                  <th>Provider</th>
                </tr>
              </thead>
              <tbody className="text-center text-dark">
                {props.plantList.length ? props.plantList.map((item,index)=>{
                  return (
                    <tr key={item.AutoID} onClick={()=>handleItem(item.AutoID,item.provider)}>
                      <td><i className="bi bi-lightbulb-fill" style={{color:light[item.Light === '-1' ? 4 : item.Light]}}></i> </td>
                      <td>{item.GoodsTypeName}</td>
                      <td>{(item.EToday/1000).toFixed(2) + ' kwh'}</td>
                      <td>{(item.ETotal/1000).toFixed(2) + ' kwh'}</td>
                      <td>{item.SetUpTime}</td>
                      <td>{item.LastUpdate}</td>
                      <td>{(item.CurrPac/1000).toFixed(2) + ' W'}</td>
                      <td>{item.provider}</td>
                    </tr>
                  )
                }) : <tr></tr>}
              </tbody>
            </Table>
          </Col>
        </Row>
      </Container>
    );
  }