import React from "react";
import Pic1 from "../../assets/img/img-600x400-1.jpg"
import Pic2 from "../../assets/img/img-600x400-2.jpg"
import Pic3 from "../../assets/img/img-600x400-3.jpg"
import Pic4 from "../../assets/img/img-600x400-4.jpg"
import Pic5 from "../../assets/img/img-600x400-5.jpg"
import Pic6 from "../../assets/img/img-600x400-6.jpg"
import {
    Link
  } from "react-router-dom";

export default function ServicesList(props) {

  return (
    <>
            <div className="container-xxl py-5">
            <div className="container">
                <div className="text-center mx-auto mb-5" style={{maxWidth: "600px"}}>
                    <h1 className="mb-4" style={{textShadow: "0px 5px 5px #ffc107",color:"#318af2"}}>Découvrez nos divers services énérgétiques</h1>
                </div>
                <div className="row g-4">
                    
                    <div className="col-md-6 col-lg-4 " >
                        <div className="service-item rounded overflow-hidden">
                            <img className="img-fluid" src={Pic1} alt=""></img>
                            <div className="position-relative p-4 pt-0">
                                <div className="service-icon">
                                    <i className="fa fa-solar-panel fa-3x"></i>
                                </div>
                                <h4 className="mb-3">Panneaux solaire</h4>
                                <p>Nous vous accompagnons dans tout type de vous projets solaires.</p>
                                <Link to="solarPanels" className="small fw-medium" >Voir plus<i className="fa fa-arrow-right ms-2"></i></Link>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6 col-lg-4 ">
                        <div className="service-item rounded overflow-hidden">
                            <img className="img-fluid" src={Pic6} alt=""></img>
                            <div className="position-relative p-4 pt-0">
                                <div className="service-icon">
                                    <i className="bi bi-tools fa-3x"></i>
                                </div>
                                <h4 className="mb-3">Maintenance</h4>
                                <p>Nous sommes experts dans plusieurs domaines liée à la production et l'economié d'energie.</p>
                                <Link to="maintenance" className="small fw-medium" >Voir plus<i className="fa fa-arrow-right ms-2"></i></Link>
                            </div>
                        </div>
                    </div>


                    {/*<div className="col-md-6 col-lg-4 ">
                        <div className="service-item rounded overflow-hidden">
                            <img className="img-fluid" src={Pic2} alt=""></img>
                            <div className="position-relative p-4 pt-0">
                                <div className="service-icon">
                                    <i className="bi bi-water fa-3x"></i>
                                </div>
                                <h4 className="mb-3">Economiseur d'eau</h4>
                                <p>Réduisez vos facture d'eau jusuq'a la moité.</p>
                                <Link to="waterSaving" className="small fw-medium" >Voir plus<i className="fa fa-arrow-right ms-2"></i></Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-4 ">
                        <div className="service-item rounded overflow-hidden">
                            <img className="img-fluid" src={Pic3} alt=""></img>
                            <div className="position-relative p-4 pt-0">
                                <div className="service-icon">
                                    <i className="fa fa-lightbulb fa-3x"></i>
                                </div>
                                <h4 className="mb-3">Economiseur éléctrique</h4>
                                <p>Economisez vos factures d'éléctricité gràce a nos films protectrice</p>
                                <Link to="electricitySaving" className="small fw-medium" >Voir plus<i className="fa fa-arrow-right ms-2"></i></Link>
                            </div>
                        </div>
                    </div> */}

                    <div className="col-md-6 col-lg-4 ">
                        <div className="service-item rounded overflow-hidden">
                            <img className="img-fluid" src={Pic4} alt=""></img>
                            <div className="position-relative p-4 pt-0">
                                <div className="service-icon">
                                    <i className="bi bi-graph-up fa-3x"></i>
                                </div>
                                <h4 className="mb-3">Côntrole et monotoring</h4>
                                <p>Controlez et suivez vos projet solaires avec notre outils.</p>
                                <Link to="controlAndMonotoring" className="small fw-medium" >Voir plus<i className="fa fa-arrow-right ms-2"></i></Link>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6 col-lg-4 ">
                        <div className="service-item rounded overflow-hidden">
                            <img className="img-fluid" src={Pic5} alt=""></img>
                            <div className="position-relative p-4 pt-0">
                                <div className="service-icon">
                                    <i className="fa fa-wind fa-3x"></i>
                                </div>
                                <h4 className="mb-3">Eolienne</h4>
                                <p>Exploitez le vent et produisez de l'elecricité.</p>
                                <Link to="windTurbin" className="small fw-medium">voir plus<i className="fa fa-arrow-right ms-2"></i></Link>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    </>
    );
}

