import React,{useContext,useState,createContext,useEffect} from "react";
import {AuthService} from "./auth.service.js"

export const AuthContextType = {
    user: undefined,
    role:undefined,
    signin: undefined,
    signout: undefined
}


let AuthContext = createContext(AuthContextType);

export  const AuthProvider = ({ children })  => {
    let [user, setUser] = useState(null);
    let [role,setRole] = useState(null);
  
    useEffect(()=>{
      if(JSON.parse(localStorage.getItem('accessToken')) !== null){
        setUser(JSON.parse(localStorage.getItem('username')));
        setRole(JSON.parse(localStorage.getItem('roles')))
        AuthService.checkUser(()=>{
          setUser(undefined);
          setRole(undefined)
        },user)
      }
    },[])

    let signin = (username,password, callback) => {
      return AuthService.login(username,password,() => {
        setUser(username);
        setRole(JSON.parse(localStorage.getItem('roles')))
        callback();
      });
    };
  
    let signout = () => {
      return AuthService.logout(() => {
        setUser(undefined);
        setRole(undefined)
      },user);
    };
    

    let value = { user,role, signin, signout };
  
    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
  }
  


export default function useAuth () {
    return useContext(AuthContext);
}