import React,{useState,useEffect} from "react";
import { PublicService } from "../../services/public.service";
import CaptchaFormulaire from "../unstructuredComponents/CaptchaFormulaire";
import SendCheck from "../unstructuredComponents/SendCheck";

export default function ContactFormulaire(props) {

    const [contactState,setContactState] = useState({})
    const contact={
        captcha:""
    }
    
    const [sendStatus, setSendStatus] = useState(undefined)
    const [formState, setFormState] = useState(0);
    const handleInfo = (e)=>{
        contact[e.target.id]=e.target.value;
    }


    const handleContact = (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        if (form.checkValidity() === false) {
          event.stopPropagation();
        }
        if(formState === 0 )
            setContactState(contact)
        if(formState === 1 )
            setContactState((prev)=>({...prev,captcha:contact.captcha}))
        setFormState(()=> formState +1);
        setTimeout(function(){form.classList.remove('was-validated');}, 1050);
      };

    const handleCaptcha = (captcha)=>{
        contact.captcha= captcha
    }

    useEffect(()=>{
         if (formState === 2){
                PublicService.setNewContact(contactState).then(res =>{
                    if(res && res.message === "captchaError"){
                        setTimeout(function(){setFormState(1)}, 2050);
                        return;
                      }
                      if(res && res.success === 200){
                        setSendStatus(true)
                        setTimeout(function(){setFormState(0)}, 10050);
                      }else{
                        setSendStatus(false)
                        setTimeout(function(){setFormState(1)}, 2050);
                      }  
                })
            }

    },[formState,contactState])

    return (
        <div className="container">
            <div className="row justify-content-md-center">
                <div className="col-8">
                    <h1 className="mb-4">Contactez nous!</h1>
                    <p className="mb-4">Vous recevez une réponse dans un délai de 48 heures aprés l'envoie de message. Renseigner bien votre mail ou numéro de téléphone pour vous contacter.</p>
                    <form>
                        <div className="row g-3">
                
                            {formState === 0 ? <Form handleInfo={handleInfo}/> : ''}
                            {formState === 1 ? <CaptchaFormulaire handleCaptcha={handleCaptcha}/> : ''}
                            {formState === 2 ? <SendCheck status={sendStatus} /> : ''}
                            <div className="col-12">
                                {formState < 2 ? <button className="btn btn-primary rounded-pill py-3 px-5" onClick={handleContact}>Envoyer</button> : '' }
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );

}

function Form (props){
    return (
    <>
        <div className="col-md-6">
            <div className="form-floating">
                <input type="text" className="form-control" id="name" onChange={props.handleInfo} placeholder="Nom"></input>
                <label htmlFor="name">Nom</label>
            </div>
        </div>
        <div className="col-md-6">
            <div className="form-floating">
                <input type="email" className="form-control" id="email" onChange={props.handleInfo} placeholder="Email"></input>
                <label htmlFor="email">Email ou Tel</label>
            </div>
        </div>
        <div className="col-12">
            <div className="form-floating">
                <input type="text" className="form-control" id="subject" onChange={props.handleInfo} placeholder="Sujet"></input>
                <label htmlFor="subject">Sujet</label>
            </div>
        </div>
        <div className="col-12">
            <div className="form-floating">
                <textarea className="form-control" placeholder="Leave a message here" id="message" onChange={props.handleInfo} style={{height: "100px"}}></textarea>
                <label htmlFor="message">Message</label>
            </div>
        </div>
    </>);
}
