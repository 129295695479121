import React, {useState,useEffect} from "react";
import Logo from "../../assets/img/logo.png"
import "../../assets/css/signin.css"
import useAuth from "../../services/authentication/useAuth"

import {
    useNavigate,
    useLocation,
    Link
} from "react-router-dom";

export default function SignInForm(props) {
    let navigate = useNavigate();
    let location = useLocation();
    let auth = useAuth();
    const [identifiant,setIdentifiant] = useState({username:'',password:''})
    let from = location.state?.from?.pathname || "/";
    const handleIdentifiant = (e) => {
        if(e.target.title === "username"){
            setIdentifiant((prev)=>{
                return {...prev, username:e.target.value}
            })
        }else{
            setIdentifiant((prev)=>{
                return {...prev, password:e.target.value}
            })
        }
    }
    useEffect(()=>{
        if(auth.user){
            navigate(from, { replace: true }); 
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[auth])
    const handleConnect = (e) => {
        e.preventDefault();
        auth.signin(identifiant.username,identifiant.password, () => {
        // Send them back to the page they tried to visit when they were
        // redirected to the login page. Use { replace: true } so we don't create
        // another entry in the history stack for the login page.  This means that
        // when they get to the protected page and click the back button, they
        // won't end up back on the login page, which is also really nice for the
        // user experience.
            navigate(from, { replace: true });
        })
    }

  return (
    <div className="container-fluid bg-light pt-5 p-0 pb-5" id="about_component">
        
        <div className="form-signin w-100 m-auto">
            <div className="text-center">
            <form>
                <img className="mb-4" src={Logo} alt="" width="180" height="150"></img>
                <h1 className="h3 mb-3 fw-normal">Espace Client</h1>

                <div className="form-floating">
                    <input defaultValue="" type="text" className="form-control" title="username" placeholder="identifiant"onChange={handleIdentifiant}></input>
                    <label htmlFor="floatingInput">Username</label>
                </div>
                <div className="form-floating">
                    <input defaultValue=""  type="password" className="form-control" title="password" placeholder="Mot de passe" onChange={handleIdentifiant}></input>
                    <label htmlFor="floatingPassword">Mot de passe</label>
                </div>
                <button className="w-100 btn btn-lg btn-primary" onClick={(e)=>{handleConnect(e)}} type="submit">Sign in</button>
                <Link className="btn btn-link text-info" to="/createAcount">Create new account</Link >
                <p className="mt-5 mb-3 text-muted">&copy; 2017 - 2023</p>
            </form>
            </div>
        </div>

    </div>
    );
}