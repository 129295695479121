import axios from "axios";
import authHeader from "./auth-header";
const API_URL = process.env.REACT_APP_API ||"http://localhost:3000";
const AUTH_PATH = "/api/auth/";

const AuthService = {
  login(username, password , callback) {
    return axios
      .post(API_URL + AUTH_PATH+"signin", { username, password })
      .then((response) => {
        if(response.data.message)
          alert(response.data.message)
        if (response.data.authorization) {
          localStorage.setItem("accessToken", JSON.stringify(response.data.authorization));
          localStorage.setItem("username", JSON.stringify(response.data.username));
          localStorage.setItem("roles", JSON.stringify(response.data.roles));
          
          callback()
        }
        return response.data;
      });
  },
  logout(callback,username) {
    localStorage.clear();
    axios
        .post(API_URL + AUTH_PATH+"signout", { username: username})
        .then((response) => {
            //logout with success
        });
      callback();
    },
  register(username, email, password) {
    return axios.post(API_URL + AUTH_PATH + "signup", {
      username,
      email,
      password,
    });
  },
  checkUser(callback,user){
    
    return axios.get(API_URL +AUTH_PATH+'user', {
      headers: authHeader()
    })
    .then((res) => {
        if(res.status === 300){
          this.logout(callback,user)
        }
    })
    .catch((error) => {
      callback()
    })
  }
}


export { AuthService };
