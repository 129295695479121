import React from "react";

export default function Documents () {

    return (
        <>
        <div>Documents</div>
        </>
        
    );
}